import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from 'context';

import App from 'components';

import 'assets/css/styles.css';
import 'assets/css/slick-slider.css';
import 'assets/css/day-picker-styles.css';

ReactDOM.render(
  <Router>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Router>,
  document.getElementById('root'),
);
