import {
  useMemo,
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';
import UAParser from 'ua-parser-js';
import { useLocation } from 'react-router-dom';

const deviceType = new UAParser().getDevice().type;

export const isDesktop = deviceType !== 'mobile' && deviceType !== 'tablet';

const getBreakpointCheck = () => window.innerWidth >= 768;

const initViewContext: View = {
  isStaging: false,
  viewSignOut() {},
  isDesktopView: getBreakpointCheck(),
  isSearchBarOpen: getBreakpointCheck(),
  setIsSearchBarOpen() {},
};

const ViewContext = createContext<View>(initViewContext);

export const useView = () => useContext<View>(ViewContext);

const ViewProvider: React.FunctionComponent = ({ children }) => {
  const { pathname } = useLocation();

  const isStaging = useMemo(() => pathname === '/staging', [pathname]);

  const [isDesktopView, setIsDesktopview] = useState<boolean>(getBreakpointCheck());

  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(getBreakpointCheck());

  const viewSignOut = () => {
    setIsDesktopview(getBreakpointCheck());
    setIsSearchBarOpen(getBreakpointCheck());
  };

  const handleBreakpoint = () => {
    const breakpointCheck = getBreakpointCheck();
    if (isDesktopView !== breakpointCheck) {
      setIsDesktopview(breakpointCheck);
    }
  };

  useEffect(() => {
    handleBreakpoint();
    window.addEventListener('resize', handleBreakpoint);
    return () => {
      window.removeEventListener('resize', handleBreakpoint);
    };
  }, [handleBreakpoint]);

  return (
    <ViewContext.Provider value={{
      isStaging,
      isDesktopView,
      isSearchBarOpen,
      viewSignOut,
      setIsSearchBarOpen,
    }}
    >
      {children}
    </ViewContext.Provider>
  );
};

export default ViewProvider;
