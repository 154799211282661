import { useState, createContext, useContext } from 'react';

const StoreContext = createContext<Store>({
  filters: new Map(),
  narratives: new Map(),
  narrativesStaged: new Map(),
  setFilters() {},
  setNarratives() {},
  setNarrativesStaged() {},
  storeSignOut() {},
});

export const useStore = () => useContext<Store>(StoreContext);

const StoreProvider: React.FunctionComponent = ({ children }) => {
  const [filters, setFilters] = useState<FiltersMap>(new Map());
  const [narratives, setNarratives] = useState<NarrativesMap>(new Map());
  const [narrativesStaged, setNarrativesStaged] = useState<NarrativesStagedMap>(new Map());

  const storeSignOut = () => {
    setFilters(new Map());
    setNarratives(new Map());
    setNarrativesStaged(new Map());
  };

  return (
    <StoreContext.Provider value={{
      filters,
      narratives,
      narrativesStaged,
      setFilters,
      storeSignOut,
      setNarratives,
      setNarrativesStaged,
    }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
