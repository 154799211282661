import { LevelEnum } from './enums';

export const publishedTodayTime = () => {
    const publishDate = new Date();
    publishDate.setHours(0, 0, 0, 0);
    return publishDate.getTime();
};

export const findStatement = <T extends Statement | StatementStaged>(
    statementUID: string | null,
    oneSubs: (OneSub<T>[]) | undefined,
    level: LevelEnum,
) => {
    const found: {
        oneSubIndex: number | null;
        twoSubIndex: number | null;
        statement: T | null,
    } = {
        oneSubIndex: null,
        twoSubIndex: null,
        statement: null,
    };

    if (!statementUID || !oneSubs?.length) {
        return found;
    }

    if (level === LevelEnum.two) {
        oneSubs.forEach(({ statement }, i) => {
            if (found.statement) return;

            if (statement.uid === statementUID) {
                found.oneSubIndex = i;
                found.statement = statement;
            }
        });
    }

    if (level === LevelEnum.three) {
        oneSubs.forEach(({ twoSubs }, i) => {
            if (found.statement) return;

            twoSubs?.forEach((statement, j) => {
                if (statement.uid === statementUID) {
                    found.oneSubIndex = i;
                    found.twoSubIndex = j;
                    found.statement = statement;
                }
            });
        });
    }

    return found;
};

export const isStatementEdited = (
    statement: (Partial<Statement> & Partial<StatementStaged>) | undefined,
) => {
    const { staging } = statement || {};

    const {
      isRefsEdited = false,
      isTitleEdited = false,
      isVisualEdited = false,
      isFiltersEdited = false,
      isPositionEdited = false,
    } = staging || {};

    return isRefsEdited || isTitleEdited || isVisualEdited || isFiltersEdited || isPositionEdited;
};

export const filterSubmitted = (found: Found, isAdmin: boolean) => (
    found.filter(({ narrative }) => {
        const { statement } = narrative;

        const { staging } = statement as StatementStaged;

        return isAdmin ? staging?.isReadyForPublish : !staging?.isReadyForPublish;
    })
);
