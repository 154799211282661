import { useState, FormEvent } from 'react';
import {
  useAuth,
  useModal,
  useStore,
  useWebSocket,
} from 'context';
import { v4 as uuidv4 } from 'uuid';
import { useSignOut } from 'utils/hooks';
import { BatchMessage } from 'context/websocket';
import { archiveVisuals, unauthedRequest } from 'utils/api';
import { Button, FormMessages, SearchFilters } from 'components/shared';
import {
  initFilters,
  isShallowMatch,
  publishedTodayTime,
  FilterCategoryEnum,
  initFilterRefrences,
} from 'utils/helpers';

import EditStatement, { createStatement } from './EditStatement';

interface Props {
  uid: string | null;
  isEditing: boolean;
}

const EditNarrative = ({
  uid,
  isEditing,
}: Props) => {
  const { currentUser } = useAuth();

  const { startSignOut } = useSignOut();

  const { sendData, storeHasInit } = useWebSocket();

  const {
    closeModal, ModalWrapper, isModalLoading, setIsModalLoading,
  } = useModal();

  const { narratives, narrativesStaged } = useStore();

  const pubNarrative = narratives?.get(uid || '') || null;

  const stagedNarrative = narrativesStaged?.get(uid || '') || null;

  const [error, setError] = useState('');

  const [success, setSuccess] = useState('');

  const [warning, setWarning] = useState('');

  const [statement, setStatement] = useState<EditStatement | null>(null);

  const [
    searchFilters,
    setSearchFilters,
  ] = useState<Filters>(initFilters);

  const onSubmit = async (formEvent: FormEvent<HTMLFormElement>) => {
    formEvent.preventDefault();

    const noRefErr = new Error('Please provide at least one reference with text');
    const noFilterErr = new Error('Please select at least one option for each filter category');
    const noStatementErr = new Error('Please provide a Statement');
    const invalidFiltersErr = new Error('New narrative requires at least one filter that does not include "None"');
    const noStatementDataErr = new Error('Unexpected Error: Did not recieve statement data - please contact an administrator');

    try {
      if (!statement) {
        throw noStatementDataErr;
      }

      if (!statement.title) {
        throw noStatementErr;
      }

      if (!(statement.refs || [])[0]?.text) {
        throw noRefErr;
      }

      const narrativeFilters: FilterReferences = { ...initFilterRefrences };

      let invalidFiltersCount = 0;

      Object.values(FilterCategoryEnum).forEach((categoryName) => {
        const searchCategory = searchFilters[categoryName];
        if (!searchCategory?.length) {
          throw noFilterErr;
        }

        const isValidFilter = searchCategory.every(({ value, label }) => (
          value
          && label
          && value.toLowerCase() !== 'none'
          && label.toLowerCase() !== 'none'
        ));

        if (!isValidFilter) {
          invalidFiltersCount += 1;
          if (invalidFiltersCount > Object.keys(narrativeFilters).length - 1) {
            throw invalidFiltersErr;
          }
          return;
        }

        narrativeFilters[categoryName] = searchCategory.map((sc) => sc.uid);
      });

      setWarning('');

      setIsModalLoading(true);

      const isFiltersEdited = (
        isEditing
        && !Object.values(FilterCategoryEnum).every((categoryName) => {
          const pubCategory = (pubNarrative?.filters || {})[categoryName] || [];
          const newCategory = narrativeFilters[categoryName] || [];
          return isShallowMatch(pubCategory, newCategory);
        })
      );

      statement.staging = {
        ...statement.staging,
        isFiltersEdited,
        isNew: !pubNarrative,
        isReadyForPublish: stagedNarrative?.statement?.staging.isReadyForPublish || false,
      };

      const narrativeUID = uid || uuidv4();

      const newStatement = await createStatement(statement, currentUser);

      const newNarrativeStaged: Narrative<StatementStaged> = {
        oneSubs: stagedNarrative?.oneSubs || [],
        filters: narrativeFilters,
        statement: newStatement,
        publishTime: publishedTodayTime(),
      };

      await new BatchMessage(
        sendData,
        {
          key: 'narrativesStaged',
          field: narrativeUID,
          value: newNarrativeStaged,
        },
      ).send();

      const pubVisual = pubNarrative?.statement?.visual;

      const stagedVisual = stagedNarrative?.statement?.visual;

      if (
        isEditing
        && stagedVisual
        && stagedVisual !== pubVisual
        && stagedVisual !== statement.visual
      ) {
        try {
          await archiveVisuals([stagedVisual], currentUser);
        } catch (err) {
          if (err === unauthedRequest) throw unauthedRequest;
          setError('Statement visuals cleanup failed - please contact your administrator.');
        }
      }

      setSuccess(`Narrative successfully ${isEditing ? 'edited' : 'created'}`);
      setIsModalLoading(false);
    } catch (err) {
      setIsModalLoading(false);

      if (
        err === noRefErr
        || err === noFilterErr
        || err === noStatementErr
        || err === invalidFiltersErr
        || err === noStatementDataErr
      ) {
        setWarning(`${err}`.replace(/Error:\s/, ''));
      } else if (err === unauthedRequest) {
        startSignOut();
      } else {
        setError('Could not add or edit - please check your internet connection and try again.');
      }
    }
  };

  if (uid && !isEditing) {
    setError('An error occured while loading - please contact your administrator.');
  }

  return (
    <ModalWrapper
      title={`${isEditing ? 'Edit' : 'Add new'} narrative`}
      banner={<FormMessages {...{ error, success, warning }} />}
      enableWrapperClickClose={false}
    >
      {!error && !success && (
        <form
          className="flex flex-col gap-6 lg:gap-10"
          onSubmit={onSubmit}
        >
          <div className="grid gap-6 lg:gap-20 lg:grid-cols-2">
            <div className="relative grid gap-4 content-start">
              <SearchFilters
                includeNone
                defaultNone={isEditing}
                isLoading={isModalLoading}
                filterReferences={stagedNarrative?.filters}
                setSearchFilters={setSearchFilters}
              />
            </div>
            <EditStatement
              isLoading={isModalLoading}
              setWarning={setWarning}
              setStatement={setStatement}
              pubStatement={pubNarrative?.statement || null}
              statementStaged={stagedNarrative?.statement || null}
            />
          </div>
          <div className="flex flex-col gap-6 lg:flex-row lg:gap-20">
            <Button
              type="button"
              title="Cancel"
              tailwindAddon="w-full"
              isDisabled={isModalLoading}
              onClick={closeModal}
            />
            <Button
              type="submit"
              title={
                !storeHasInit ? 'Loading...'
                : isEditing ? 'Save'
                : 'Create'
              }
              styleType="confirm"
              tailwindAddon="w-full"
              isLoading={isModalLoading}
              isDisabled={!storeHasInit}
            />
          </div>
        </form>
      )}
    </ModalWrapper>
  );
};

export default EditNarrative;
