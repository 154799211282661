import { useState, FormEvent } from 'react';
import clone from 'rfdc';
import { useSignOut } from 'utils/hooks';
import { BatchMessage } from 'context/websocket';
import { Button, FormMessages } from 'components/shared';
import { archiveVisuals, unauthedRequest } from 'utils/api';
import {
  LevelEnum,
  findStatement,
  publishedTodayTime,
} from 'utils/helpers';
import {
  useAuth,
  useModal,
  useStore,
  useWebSocket,
} from 'context';

import EditStatement, { createStatement } from './EditStatement';

interface Props {
  uid: string;
  level: LevelEnum;
  isEditing: boolean;
  oneSubIndex: number;
  statementUID: string | null;
}

const EditSupporting = ({
  uid,
  level,
  isEditing = false,
  oneSubIndex,
  statementUID = null,
}: Props) => {
  const statementType = level === LevelEnum.two ? 'supporting statement' : 'additional statement';

  const statementTypeTitleCase = statementType.charAt(0).toUpperCase() + statementType.slice(1);

  const { currentUser } = useAuth();

  const { sendData } = useWebSocket();

  const { startSignOut } = useSignOut();

  const {
    closeModal,
    ModalWrapper,
    isModalLoading,
    setIsModalLoading,
  } = useModal();

  const { narratives, narrativesStaged } = useStore();

  const [error, setError] = useState('');

  const [success, setSuccess] = useState('');

  const [warning, setWarning] = useState('');

  const [statement, setStatement] = useState<EditStatement | null>(null);

  const pubNarrative = narratives.get(uid);

  const stagedNarrative = narrativesStaged.get(uid);

  const {
    statement: pubStatement,
  } = findStatement(statementUID, pubNarrative?.oneSubs, level);

  const {
    twoSubIndex,
    statement: stagedStatement,
  } = findStatement(statementUID, stagedNarrative?.oneSubs, level);

  if (!stagedNarrative || oneSubIndex === null) return <></>;

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const noRefErr = new Error('Please provide at least one reference with text');
    const noAddLevelErr = new Error('Please selecte a Statement Level');
    const noStatementErr = new Error('Please provide a Statement');
    const noSupporitingErr = new Error('Please select a Supporting statement');
    const noStatementDataErr = new Error('Unexpected Error: Did not recieve statement data - please contact an administrator');

    try {
      if (!statement) {
        throw noStatementDataErr;
      }

      if (!statement.title) {
        throw noStatementErr;
      }

      if (!(statement.refs || [])[0]?.text) {
        throw noRefErr;
      }

      setWarning('');

      setIsModalLoading(true);

      statement.staging.isNew = pubStatement === null;

      const newStatement = await createStatement(statement, currentUser);

      const stagedNarrativeClone = clone()(stagedNarrative);

      stagedNarrativeClone.publishTime = publishedTodayTime();

      const oneSubs = stagedNarrativeClone.oneSubs || [];

      const editLevelTwoStatement = () => {
        if (isEditing) {
          oneSubs[oneSubIndex] = {
            twoSubs: oneSubs[oneSubIndex].twoSubs || [],
            statement: newStatement,
          };
        } else {
          oneSubs.push({
            twoSubs: [],
            statement: newStatement,
          });
        }
      };

      const editLevelThreeStatement = () => {
        if (isEditing && twoSubIndex !== null) {
          oneSubs[oneSubIndex].twoSubs[twoSubIndex] = newStatement;
        } else {
          oneSubs[oneSubIndex].twoSubs.push(newStatement);
        }
      };

      if (level === LevelEnum.two) {
        editLevelTwoStatement();
      } else {
        editLevelThreeStatement();
      }

      stagedNarrativeClone.oneSubs = oneSubs;

      await new BatchMessage(
        sendData,
        {
          key: 'narrativesStaged',
          field: uid,
          value: stagedNarrativeClone,
        },
      ).send();

      const editVisual = stagedStatement?.visual;

      if (
        isEditing
        && editVisual
        && editVisual !== pubStatement?.visual
        && editVisual !== statement.visual
      ) {
        try {
          await archiveVisuals([editVisual], currentUser);
        } catch (err) {
          if (err === unauthedRequest) throw unauthedRequest;
          setError('Statement visuals cleanup failed - please contact your administrator');
        }
      }

      setSuccess(`${statementTypeTitleCase} successfully ${isEditing ? 'edited' : 'created'}`);
      setIsModalLoading(false);
    } catch (err) {
      setIsModalLoading(false);

      if (
        err === noRefErr
        || err === noAddLevelErr
        || err === noStatementErr
        || err === noSupporitingErr
        || err === noStatementDataErr
      ) {
        setWarning(`${err}`.replace(/Error:\s/, ''));
      } else if (err === unauthedRequest) {
        startSignOut();
      } else {
        setError('Could not add or edit - please check your internet connection');
      }
    }
  };

  return (
    <ModalWrapper
      title={`${isEditing ? 'Edit' : 'Add'} ${statementType}`}
      banner={<FormMessages {...{ error, success, warning }} />}
      enableWrapperClickClose={false}
    >
      {!error && !success && (
        <form
          className="flex flex-col gap-6 lg:gap-10"
          onSubmit={handleSubmit}
        >
          <div className="grid gap-6 lg:gap-20 lg:grid-cols-2">
            <div className="grid gap-6 lg:gap-8 content-start md:text-sm text-azBlue-700">
              <div>
                <span className="font-bold">
                  Key statement:
                </span>
                <p>
                  {stagedNarrative.statement?.title || 'An unexpected error occured.'}
                </p>
              </div>
              {level === LevelEnum.three && (
                <div>
                  <span className="font-bold text-azBlue-700">
                    Supporting statement:
                  </span>
                  <p>
                    {(stagedNarrative.oneSubs || [])[oneSubIndex]?.statement?.title || 'An unexpected error occured.'}
                  </p>
                </div>
              )}
            </div>
            <EditStatement
              isLoading={isModalLoading}
              setWarning={setWarning}
              setStatement={setStatement}
              pubStatement={pubStatement}
              statementStaged={stagedStatement}
            />
          </div>
          <div className="flex flex-col gap-6 lg:flex-row lg:gap-20">
            <Button
              type="button"
              title="Cancel"
              tailwindAddon="w-full"
              isDisabled={isModalLoading}
              onClick={closeModal}
            />
            <Button
              type="submit"
              title={isEditing ? 'Save' : 'Create'}
              styleType="confirm"
              isLoading={isModalLoading}
              tailwindAddon="w-full"
            />
          </div>
        </form>
      )}
    </ModalWrapper>
  );
};

export default EditSupporting;
