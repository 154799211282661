import { shrinkVisual } from 'utils/helpers';
import {
  S3_EP,
  REST_EP,
  unauthedRequest,
  errorResponseStatus,
} from './helpers';

export const archiveVisuals = async (visualURLs: string[], currentUser: CurrentUser) => {
  try {
    const visualIconUIDs = visualURLs.map((url) => url.split('/').slice(-1)[0]);
    const res = await fetch(
      `${REST_EP}/img-archive`,
      {
        method: 'POST',
        headers: {
          'x-auth-token': await currentUser?.getIdToken() || '',
          'x-auth-token-type': currentUser?.tokenType || '',
        },
        body: JSON.stringify({
          keys: [
            ...visualIconUIDs,
            ...visualIconUIDs.map((iconUID) => iconUID.replace('-icon', '')),
          ],
        }),
      },
    );
    if (res.status !== 200) throw errorResponseStatus;
  } catch (e) {
    throw e === errorResponseStatus ? errorResponseStatus : unauthedRequest;
  }
};

const presignedFetch = async (key: string, currentUser: CurrentUser) => {
  try {
    const res = await fetch(
      `${REST_EP}/img-upload`,
      {
        method: 'POST',
        headers: {
          'x-auth-token': await currentUser?.getIdToken() || '',
          'x-auth-token-type': currentUser?.tokenType || '',
        },
        body: JSON.stringify({ key }),
      },
    );
    if (res.status !== 200) throw errorResponseStatus;
    return await res.json() as { url: string };
  } catch (e) {
    throw e === errorResponseStatus ? errorResponseStatus : unauthedRequest;
  }
};

const uploadImg = async (presignedURL: string, imgFile: File | Blob) => {
  try {
    const body = imgFile;
    const res = await fetch(presignedURL, { method: 'PUT', body });
    if (res.status !== 200) throw errorResponseStatus;
  } catch (e) {
    throw e === errorResponseStatus ? errorResponseStatus : unauthedRequest;
  }
};

export const uploadVisualToS3 = async (
  key: string,
  imgFile: File,
  currentUser: CurrentUser,
) => {
  const icon = imgFile.type !== 'image/gif' ? await shrinkVisual(imgFile) : imgFile;
  if (!icon) throw new Error('icon is null');
  const presignedURL = await presignedFetch(key, currentUser);
  const presignedIconURL = await presignedFetch(`${key}-icon`, currentUser);
  await uploadImg(presignedURL.url, imgFile);
  await uploadImg(presignedIconURL.url, icon);
  return `${S3_EP}${key}-icon`;
};
