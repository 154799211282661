import { useState, useEffect } from 'react';

type UseWindowClickType = (
    element?: React.MutableRefObject<HTMLDivElement | null>,
    isLoading?: boolean,
    closeCallback?: (() => void) | undefined
) => [boolean, React.Dispatch<React.SetStateAction<boolean>>];

const useWindowClick: UseWindowClickType = (divRef = undefined) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  useEffect(() => {
    const pageClick = (e: MouseEvent): void => {
      if (!divRef?.current?.contains(e.target as Node)) {
        setIsActive(false);
      }
    };
    if (isActive) window.addEventListener('click', pageClick);
    return () => window.removeEventListener('click', pageClick);
  }, [isActive, divRef]);
  return [isActive, setIsActive];
};

export default useWindowClick;
