import {
  useRef,
  useState,
  FormEvent,
  useEffect,
  useCallback,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useRandomValues } from 'utils/hooks';
import { BatchMessage } from 'context/websocket';
import { useStore, useModal, useWebSocket } from 'context';
import {
  moveItem,
  filterNames,
  removeControlChars,
  FilterCategoryEnum,
} from 'utils/helpers';
import {
  Input,
  Button,
  InfoIcon,
  FormMessages,
  SingleSelect,
} from 'components/shared';

const categoryOptions = Object.keys(FilterCategoryEnum).map((categoryName) => ({
  uid: 'N/A',
  label: filterNames[categoryName],
  value: categoryName,
}));

const actionOptions = [
  {
    uid: 'N/A',
    label: 'Add',
    value: 'add',
  }, {
    uid: 'N/A',
    label: 'Edit',
    value: 'edit',
  }, {
    uid: 'N/A',
    label: 'Move',
    value: 'move',
  }, {
    uid: 'N/A',
    label: 'Delete',
    value: 'delete',
  },
];

const narrativeTagError = new Error('narrativeTagError');
const labelAlreadyExists = new Error('labelAlreadyExists');

const InputLabel = ({ label, htmlFor }: { label: string; htmlFor?: string }) => (
  <label
    className="inline-flex gap-1 text-azBlue-700 font-semibold text-base md:text-sm"
    htmlFor={htmlFor}
  >
    {label}
    <InfoIcon className="self-start" />
  </label>
);

const EditFilters = () => {
  const { sendData, storeHasInit } = useWebSocket();
  const { narratives, narrativesStaged, filters } = useStore();
  const {
    ModalWrapper, isModalLoading, setIsModalLoading,
  } = useModal();

  const { getRandomFilterParam } = useRandomValues();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [warning, setWarning] = useState('');

  const [action, setAction] = useState<OptionType | null>(actionOptions[0]);
  const [catOption, setCatOption] = useState<OptionType | null>(categoryOptions[0]);

  const [index, setIndex] = useState<OptionType | null>(null);
  const indexRef = useRef<SingleRef>(null);

  const [deleteSelect, setDeleteSelect] = useState<OptionType | null>(null);
  const deleteSelectRef = useRef<SingleRef>(null);

  const [editSelect, setEditSelect] = useState<OptionType | null>(null);
  const editSelectRef = useRef<SingleRef>(null);

  const [moveSelect, setMoveSelect] = useState<OptionType | null>(null);
  const [moveOptions, setMoveOptions] = useState<OptionType[]>([]);
  const moveSelectRef = useRef<SingleRef>(null);

  const labelRef = useRef<HTMLInputElement | null>(null);

  const isOptionValid = (option: OptionType | null) => (
    option?.label
    && option.value
    && option.label.trim().toLowerCase() !== 'none'
    && option.value.trim().toLowerCase() !== 'none'
    && option.value.trim().toLowerCase() !== 'error'
  );

  const handleMove = async (positionOption: OptionType, moveOption: OptionType) => {
    try {
      const categoryName = catOption?.value as FilterCategoryEnum;

      const category = filters.get(categoryName || '');

      if (
        !category
        || !categoryName
        || !isOptionValid(positionOption)
        || !isOptionValid(moveOption)
      ) {
        throw new Error('handleMove: unexpectedly missing value');
      }

      const filteredCategory = [...category]?.filter((filter) => (
        filter.label !== moveSelect?.label
      ));

      const newOptions = moveItem(
        filteredCategory,
        parseInt(positionOption.value, 10) || 0,
        null,
        moveOption,
      );

      await new BatchMessage(
        sendData,
        {
          key: 'filters',
          field: categoryName,
          value: newOptions,
        },
      ).send();

      setSuccess(`${catOption?.label} option successfully moved`);
    } catch (_) {
      setError('Could not move option - please check your connection and try again.');
    }
    setIsModalLoading(false);
  };

  const handleAdd = async (positionOption: OptionType, label: string) => {
    try {
      const newOption = {
        label,
        uid: uuidv4(),
        value: getRandomFilterParam(5),
      };

      const categoryName = catOption?.value as FilterCategoryEnum;

      if (!categoryName
        || !isOptionValid(positionOption)
        || !isOptionValid(newOption)
      ) {
        throw new Error('handleAdd: unexpectedly missing value');
      }

      const category = filters.get(categoryName) || [];

      category.forEach((filter) => {
        if (filter.label.toLowerCase() === newOption.label.toLowerCase()) {
          throw labelAlreadyExists;
        }
      });

      const newOptions = moveItem(
        category,
        parseInt(positionOption.value, 10) || 0,
        null,
        newOption,
      );

      await new BatchMessage(
        sendData,
        {
          key: 'filters',
          field: categoryName,
          value: newOptions,
        },
      ).send();

      setSuccess(`${catOption?.label} option successfully added`);
    } catch (err) {
      if (err === labelAlreadyExists) {
        setWarning(`${label} already exists as a label.`);
      } else {
        setError('Could not add option - please check your connection and try again.');
      }
    }
    setIsModalLoading(false);
  };

  const handleDelete = async (deleteOption: OptionType) => {
    try {
      const categoryName = catOption?.value as FilterCategoryEnum;

      const category = filters.get(categoryName || '');

      narratives.forEach((narrative) => {
        if (!narrative.filters) return;
        const narrativeCategory = narrative.filters[categoryName] || [];
        if (narrativeCategory.some((filterUID) => filterUID === deleteOption.uid)) {
          throw narrativeTagError;
        }
      });

      narrativesStaged.forEach((narrative) => {
        if (!narrative.filters) return;
        const narrativeCategory = narrative.filters[categoryName] || [];
        if (narrativeCategory.some((filterUID) => filterUID === deleteOption.uid)) {
          throw narrativeTagError;
        }
      });

      if (
        !category
        || !categoryName
        || !isOptionValid(deleteOption)
      ) {
        throw new Error('handleDelete: unexpectedly missing value');
      }

      const filteredCategory = [...category]?.filter((filter) => (
        filter.label !== deleteOption?.label
      ));

      await new BatchMessage(
        sendData,
        {
          key: 'filters',
          field: categoryName,
          value: filteredCategory,
        },
      ).send();

      setSuccess(`${catOption?.label} option successfully deleted`);
    } catch (err) {
      if (err === narrativeTagError) {
        setWarning(`Cannot delete: ${deleteOption.label} is assigned to one or more narratives. Remove filter from narrative(s) before deleting.`);
      } else {
        setError('Could not delete option - please check your connection and try again.');
      }
    }
    setIsModalLoading(false);
  };

  const handleEdit = async (editSelectArg: OptionType, label: string) => {
    try {
      const categoryName = catOption?.value as FilterCategoryEnum;

      if (!categoryName || !isOptionValid(editSelectArg)) {
        throw new Error('handleEdit: unexpectedly missing value');
      }

      const category = (filters.get(categoryName || '') || []).map((filter) => {
        if (filter.label === label && filter.uid !== editSelectArg.uid) {
          throw labelAlreadyExists;
        }

        return filter.uid === editSelectArg.uid
          ? {
            label,
            uid: filter.uid,
            value: filter.value,
          }
          : filter;
      });

      await new BatchMessage(
        sendData,
        {
          key: 'filters',
          field: categoryName,
          value: category,
        },
      ).send();

      setSuccess(`${catOption?.label} option successfully edited`);
    } catch (err) {
      if (err === labelAlreadyExists) {
        setWarning(`${label} already exists as a label.`);
      } else {
        setError('Could not rename option - please check your connection and try again.');
      }
    }
    setIsModalLoading(false);
  };

  const onSubmit = async (formEvent: FormEvent<HTMLFormElement>) => {
    formEvent.preventDefault();
    setWarning('');
    setIsModalLoading(true);

    const handleInvalidFormData = (msg: string) => {
      setWarning(`Missing required input value(s): ${msg}`);
      setIsModalLoading(false);
    };

    const label = removeControlChars(labelRef?.current?.value.trim() || '');

    switch (action?.value) {
      case 'add':
        if (!index || !label) {
          handleInvalidFormData([
            !label && 'Label',
            !index && 'Insert Below',
          ].filter((msg) => msg).join(' | '));
          return;
        }
        handleAdd(index, label);
        break;
      case 'edit':
        if (!editSelect || !label) {
          handleInvalidFormData([
            !label && 'Label',
            !editSelect && 'Filter',
          ].filter((msg) => msg).join(' | '));
          return;
        }
        handleEdit(editSelect, label);
        break;
      case 'delete':
        if (!deleteSelect) {
          handleInvalidFormData('Filter');
          return;
        }
        handleDelete(deleteSelect);
        break;
      case 'move':
        if (!moveSelect || !index) {
          handleInvalidFormData([
            !moveSelect && 'Filter',
            !index && 'Insert Below',
          ].filter((msg) => msg).join(' | '));
          return;
        }
        handleMove(index, moveSelect);
        break;
      default:
        handleInvalidFormData('Action Type');
    }
  };

  const createSelectOptions = useCallback((type = 'default') => {
    const noneOption = {
      label: 'None (first entry)',
      value: '0',
    } as OptionType;

    const category = filters.get(catOption?.value as FilterCategoryEnum || '');

    const filteredCategory = (category || []).filter((o) => o.label !== moveSelect?.label);

    const options = [
      ...(type === 'indexValues'
        ? filteredCategory.map((o, i) => ({
          uid: o.uid,
          label: o.label,
          value: String(i + 1),
        }))
        : category || []),
    ];

    return type === 'indexValues'
      ? [
        noneOption,
        ...options,
      ]
      : options;
  }, [moveSelect, catOption, filters]);

  useEffect(() => {
    setWarning('');

    if (labelRef.current) {
      labelRef.current.value = '';
    }

    indexRef.current?.clearValue();
    editSelectRef.current?.clearValue();
    moveSelectRef.current?.clearValue();
    deleteSelectRef.current?.clearValue();
  }, [catOption, action]);

  useEffect(() => {
    indexRef.current?.clearValue();
    setMoveOptions(createSelectOptions('indexValues'));
  }, [moveSelect]);

  return (
    <ModalWrapper
      width="md:w-128"
      title="Edit filters"
      banner={<FormMessages {...{ error, success, warning }} />}
      enableWrapperClickClose={false}
    >
      {!error && !success && (
        <div className="grid gap-4">
          <div className="grid">
            <InputLabel label="Category" htmlFor="edit-filters-category" />
            <SingleSelect
              innerProps={{
                inputId: 'edit-filters-category',
                defaultValue: catOption,
                onChange: setCatOption,
                options: categoryOptions,
                isLoading: isModalLoading,
                isDisabled: isModalLoading,
              }}
            />
          </div>
          <div className="grid">
            <InputLabel label="Action" htmlFor="edit-filters-action" />
            <SingleSelect
              innerProps={{
                inputId: 'edit-filters-action',
                defaultValue: action,
                onChange: setAction,
                options: actionOptions,
                isLoading: isModalLoading,
                isDisabled: isModalLoading,
              }}
            />
          </div>
          <form
            className="grid gap-4"
            onSubmit={onSubmit}
          >
            {(action?.value || 'add') === 'add' && (
              <>
                <div className="grid gap-1">
                  <InputLabel label="Insert Below" htmlFor="edit-filters-add-insert" />
                  <SingleSelect
                    innerProps={{
                      inputId: 'edit-filters-add-insert',
                      defaultValue: index,
                      onChange: setIndex,
                      options: createSelectOptions('indexValues'),
                      isLoading: isModalLoading,
                      isDisabled: isModalLoading,
                    }}
                    innerRef={indexRef}
                  />
                </div>
                <div className="grid gap-1 pb-2">
                  <InputLabel label="Label" htmlFor="edit-filters-add-input" />
                  <Input
                    id="edit-filters-add-input"
                    type="text"
                    disabled={isModalLoading}
                    innerRef={labelRef}
                    isDisabled={isModalLoading}
                    required
                  />
                </div>
                {/* <label className="grid gap-1">
                  <InputLabel label="Value" />
                  <Input
                    type="text"
                    disabled={isModalLoading}
                    innerRef={valueRef}
                    isDisabled={isModalLoading}
                    required
                  />
                </label> */}
              </>
            )}
            {action?.value === 'delete' && (
              <div className="grid gap-1">
                <InputLabel label="Filter" htmlFor="edit-filters-delete" />
                <SingleSelect
                  innerProps={{
                    inputId: 'edit-filters-delete',
                    defaultValue: deleteSelect,
                    onChange: setDeleteSelect,
                    options: createSelectOptions(),
                    isLoading: isModalLoading,
                    isDisabled: isModalLoading,
                  }}
                  innerRef={deleteSelectRef}
                />
              </div>
            )}
            {action?.value === 'move' && (
              <>
                <div className="grid gap-1">
                  <InputLabel label="Filter" htmlFor="edit-filters-move" />
                  <SingleSelect
                    innerProps={{
                      inputId: 'edit-filters-move',
                      defaultValue: moveSelect,
                      onChange: setMoveSelect,
                      options: createSelectOptions(),
                      isLoading: isModalLoading,
                      isDisabled: isModalLoading,
                    }}
                    innerRef={moveSelectRef}
                  />
                </div>
                <div className="grid gap-1">
                  <InputLabel label="Insert Below" htmlFor="edit-filters-move-insert" />
                  <SingleSelect
                    innerProps={{
                      inputId: 'edit-filters-move-insert',
                      defaultValue: index,
                      onChange: setIndex,
                      options: moveOptions,
                      isLoading: isModalLoading,
                      isDisabled: isModalLoading,
                    }}
                    innerRef={indexRef}
                  />
                </div>
              </>
            )}
            {action?.value === 'edit' && (
              <>
                <div className="grid gap-1">
                  <InputLabel label="Filter" htmlFor="edit-filters-edit" />
                  <SingleSelect
                    innerProps={{
                      inputId: 'edit-filters-edit',
                      defaultValue: editSelect,
                      onChange: (option) => {
                        if (labelRef.current) {
                          labelRef.current.value = option?.label || '';
                        }

                        setEditSelect({
                          uid: option?.uid || '',
                          label: option?.label || '',
                          value: option?.value || '',
                        });
                      },
                      options: createSelectOptions(),
                      isLoading: isModalLoading,
                      isDisabled: isModalLoading,
                    }}
                    innerRef={editSelectRef}
                  />
                </div>
                <div className="grid gap-1 pb-2">
                  <InputLabel label="Label" htmlFor="edit-filters-edit-input" />
                  <Input
                    id="edit-filters-edit-input"
                    type="text"
                    disabled={isModalLoading}
                    innerRef={labelRef}
                    isDisabled={isModalLoading}
                    required
                  />
                </div>
                {/* <label className="grid gap-1">
                  <InputLabel label="Value" />
                  <Input
                    type="text"
                    disabled={isModalLoading}
                    innerRef={valueRef}
                    isDisabled={isModalLoading}
                    required
                  />
                </label> */}
              </>
            )}
            <Button
              style={{ marginTop: '1rem' }}
              type="submit"
              title={
                !storeHasInit
                  ? 'Loading...'
                  : `${action?.label} ${catOption?.label}`
              }
              styleType="confirm"
              tailwindAddon="w-full"
              isLoading={isModalLoading}
              isDisabled={!storeHasInit}
            />
          </form>
        </div>
      )}
    </ModalWrapper>
  );
};

export default EditFilters;
