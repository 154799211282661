export const SpinLoader = () => (
  <div className="spin-loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5" />
);

export const GridLoader = () => (
  <div className="h-screen w-full flex justify-center items-center">
    <div className="grid-loader">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);
