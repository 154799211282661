import { LevelEnum, ParamsEnum } from 'utils/helpers';

const parseLevelParam = (param: string | null) => (
  param === LevelEnum.one ? LevelEnum.one
  : param === LevelEnum.two ? LevelEnum.two
  : LevelEnum.three
);

const parseDisplayParams = (query: URLSearchParams): DisplayParams => ({
  [ParamsEnum.display]: parseLevelParam(query.get(ParamsEnum.display)),
});

export default parseDisplayParams;
