import { useMemo } from 'react';
import { useDisplay } from 'context';
import { useHistory, useLocation } from 'react-router-dom';
import { LevelEnum, ParamsEnum, FilterCategoryEnum } from 'utils/helpers';

interface Params {
    [key: string]: string;
}

const getSearch = (params: URLSearchParams) => {
    let search = '';

    params.forEach((value, key) => {
        if (!search) {
            search += `?${key}=${value}`;
        } else {
            search += `&${key}=${value}`;
        }
    });

    return search;
};

const createFilterParams = (filters: Filters) => {
    const filterParams: Params = {};

    Object.values(FilterCategoryEnum).forEach((categoryName) => {
        const category = filters[categoryName];

        const paramName = ParamsEnum[categoryName];

        const paramValue = category.map(({ value }) => value).join('|');

        if (paramValue) {
            filterParams[paramName] = paramValue;
        }
    });

    return filterParams;
};

const useQuery = () => {
    const history = useHistory();

    const { level } = useDisplay();

    const { search } = useLocation();

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    const pageHasSearchParams = useMemo(() => {
        const params = Object.fromEntries(searchParams.entries());

        delete params.display;

        return Object.values(params).filter((v) => v).length > 0;
    }, [searchParams]);

    const setLevelParam = (levelArg: LevelEnum) => {
        searchParams.set(ParamsEnum.display, levelArg);

        history.push({ search: getSearch(searchParams) });
    };

    const setSearchParams = ({
        mainUID,
        dateType,
        dateTime,
        keywordsPiped,
        searchFilters,
    }: Searched) => {
        const params = new URLSearchParams();

        params.set(ParamsEnum.display, level);

        if (mainUID) {
            params.set(ParamsEnum.uid, encodeURIComponent(mainUID));

            history.push({ search: getSearch(params) });

            return;
        }

        const filterParams = createFilterParams(searchFilters);

        if (!Object.keys(filterParams).length && !keywordsPiped) {
            history.push({ search: getSearch(params) });

            return;
        }

        const updatedParams: Params = {
            ...filterParams,
            [ParamsEnum.dateTime]: String(dateTime),
            [ParamsEnum.dateType]: dateType,
            [ParamsEnum.keywords]: keywordsPiped || '',
        };

        Object.keys(updatedParams).forEach((paramKey) => {
            const paramValue = updatedParams[paramKey];

            if (paramValue) {
                params.set(paramKey, encodeURIComponent(paramValue));
            }
        });

        history.push({ search: getSearch(params) });
    };

    const resetParams = () => {
        const params = new URLSearchParams();

        params.set(ParamsEnum.display, LevelEnum.three);

        const paramsStr = getSearch(params);

        const paramsCurrent = history.location?.search?.replace('?', '');

        if (paramsStr !== paramsCurrent) {
            history.push({ search: paramsStr });
        }
    };

    return {
        resetParams,
        searchParams,
        setLevelParam,
        setSearchParams,
        pageHasSearchParams,
    };
};

export default useQuery;
