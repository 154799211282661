/* eslint-disable react/no-array-index-key */

import { useState, useRef, useEffect } from 'react';
import validUrl from 'valid-url';
import { v4 as uuidv4 } from 'uuid';
import { uploadVisualToS3 } from 'utils/api';
import { Input, Button, SingleSelect } from 'components/shared';
import {
  isVisualSizeOK,
  isVisualFileType,
} from 'utils/helpers';

export const createStatement = async (
  statement: EditStatement,
  currentUser: CurrentUser,
): Promise<StatementStaged> => ({
    ...statement,
    visual: statement.visual instanceof File
      ? await uploadVisualToS3(uuidv4(), statement.visual, currentUser)
      : statement.visual,
    publishTime: 0,
});

const visualActions = [
  {
    uid: 'N/A',
    label: 'No Change',
    value: 'no-change',
  }, {
    uid: 'N/A',
    label: 'Update',
    value: 'update',
  }, {
    uid: 'N/A',
    label: 'Remove',
    value: 'remove',
  }, {
    uid: 'N/A',
    label: 'Restore Published',
    value: 'published',
  },
];

interface Visual {
  name: string;
  data: File | string;
  action: OptionType | null;
}

interface Props {
  isLoading: boolean;
  setWarning: React.Dispatch<React.SetStateAction<string>>;
  setStatement: React.Dispatch<React.SetStateAction<EditStatement | null>>;
  pubStatement: Statement | null;
  statementStaged: StatementStaged | null;
}

const EditStatement = ({
  isLoading,
  setWarning,
  setStatement,
  pubStatement = null,
  statementStaged = null,
}: Props) => {
  const {
    refs: pubRefs,
    title: pubTitle,
    visual: pubVisual,
  } = pubStatement || {};

  const { visual: defaultVisual = '' } = statementStaged || {};

  const defaultRefs = statementStaged?.refs?.length
    ? statementStaged?.refs
    : [{ text: '', url: '' }];

  const [refs, setRefs] = useState<Reference[]>(defaultRefs);

  const [title, setTitle] = useState<string>(statementStaged?.title || '');

  const [visual, setVisual] = useState<Visual>({
    name: 'No visual chosen',
    data: defaultVisual,
    action: visualActions[defaultVisual ? 0 : 1],
  });

  const visualRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const actionValue = visual.action?.value;

    const stageVisual = actionValue === 'update' && visual.data ? visual.data
                      : actionValue === 'published' ? pubVisual || ''
                      : actionValue === 'remove' ? ''
                      : defaultVisual;

    const isTitleEdited = pubTitle !== undefined && pubTitle !== title;

    const isVisualEdited = pubVisual !== undefined && pubVisual !== stageVisual;

    const isRefsEdited = (pubRefs || []).length > 0 && (
      (pubRefs || []).some((pubRef, i) => {
        const { text: pubText, url: pubURL } = pubRef || {};
        const { text, url } = refs[i] || {};
        return pubText !== text || pubURL !== url;
      })
      || (pubRefs || []).length !== refs.length
    );

    setStatement({
      uid: statementStaged?.uid || uuidv4(),
      refs: refs.filter(({ text }) => text),
      title,
      visual: stageVisual,
      staging: {
        ...statementStaged?.staging || {},
        isNew: false,
        isDeleted: false,
        isRefsEdited,
        isTitleEdited,
        isVisualEdited,
      },
    });
  }, [refs, title, visual, defaultVisual, pubRefs, pubTitle, pubVisual]);

  return (
    <div className="grid gap-6 content-start">
      <div className="grid gap-1">
        <label
          className="text-azBlue-700 font-bold text-base md:text-sm"
          htmlFor="title"
        >
          Statement
        </label>
        <textarea
          id="title"
          rows={6}
          className={`
            w-full text-base md:text-sm border focus:ring-2 focus:border-white focus:ring-azBlue-700 focus:outline-none
            ${isLoading ? 'border-inputBorder-300 bg-inputBorder-200' : 'border-azBlue-700'}
          `}
          style={{
            minHeight: '38px',
            maxHeight: '250px',
            padding: '10px 10px',
          }}
          defaultValue={statementStaged?.title}
          onChange={(e) => {
            setTitle(e.target.value.trim());
          }}
          onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
          disabled={isLoading}
        />
      </div>
      <div className="flex flex-col gap-3">
        {refs.map((r, i) => (
          <div key={`ref-key-${i}`} className="flex flex-col gap-1">
            <span className="text-azBlue-700 font-bold text-base md:text-sm cursor-default">
              Reference #
              {i + 1}
            </span>
            <div className="flex flex-col gap-0.5">
              <label
                className="w-12 text-azBlue-700 text-base md:text-sm"
                htmlFor={`ref${i}-text`}
              >
                Text
              </label>
              <Input
                id={`ref${i}-text`}
                type="text"
                defaultValue={r.text}
                onChange={(e) => {
                  const refsCopy = [...refs];
                  refsCopy[i].text = e.target.value.trim();
                  setRefs(refsCopy);
                }}
                onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
                isDisabled={isLoading}
              />
            </div>
            <div className="flex flex-col gap-0.5">
              <label
                htmlFor={`ref${i}-url`}
                className="w-12 text-azBlue-700 text-base md:text-sm"
              >
                Link
              </label>
              <Input
                id={`ref${i}-url`}
                type="text"
                defaultValue={r.url}
                onChange={(e) => {
                  const value = e.target.value.trim().replace('http://', '');

                  const url = value && !value.includes('https://')
                    ? `https://${value}`
                    : value;

                  if (url && !validUrl.isWebUri(url)) {
                    return;
                  }

                  const refsCopy = [...refs];
                  refsCopy[i].url = url;
                  setRefs(refsCopy);
                }}
                onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
                isDisabled={isLoading}
              />
            </div>
          </div>
        ))}
        <div className="flex justify-between text-base md:text-sm">
          <Button
            title="Add"
            type="button"
            styleType="outline"
            onClick={(e) => {
              e.preventDefault();
              if (isLoading) return;
              const refsCopy = [...refs];
              refsCopy.push({ text: '', url: '' });
              setRefs(refsCopy);
            }}
          />
          <Button
            title="Remove"
            type="button"
            style={{ padding: '0 0.5rem' }}
            styleType="outline"
            onClick={(e) => {
              e.preventDefault();
              if (isLoading) return;
              const refsCopy = [...refs];
              if (refsCopy.length > 1) {
                refsCopy.pop();
                setRefs(refsCopy);
              }
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <label
          className="text-azBlue-700 font-bold text-base md:text-sm"
          htmlFor="visual"
        >
          Visual (jpeg, png, or gif - max. 5MB)
        </label>
        {(defaultVisual.length > 0 || (pubVisual || []).length > 0) && (
          <SingleSelect
            innerProps={{
              id: 'visual',
              defaultValue: visual.action,
              onChange: ((action) => {
                setVisual({
                  ...visual,
                  action,
                });
              }),
              options: statementStaged?.staging?.isVisualEdited
                ? visualActions
                : visualActions.slice(0, 3),
            }}
          />
        )}
        {visual.action?.value === 'no-change' && defaultVisual && (
          <img
            src={defaultVisual}
            width="100px"
            style={{ maxHeight: '96px' }}
            className="border pt-2"
            alt={defaultVisual.slice(0, 20)}
          />
        )}
        {visual.action?.value === 'published' && pubVisual && (
          <>
            {pubVisual ? (
              <img
                src={pubVisual}
                width="100px"
                style={{ maxHeight: '96px' }}
                className="border mt-2"
                alt={defaultVisual.slice(0, 20)}
              />
            ) : (
              <span className="mt-1 p-3 bg-yellow-100 text-yellow-900 rounded text-sm">
                Warning: No public visual available - will remove staging visual.
              </span>
            )}
          </>
        )}
        {visual.action?.value === 'update' && (
          <>
            <input
              id="visual"
              type="file"
              accept="image/*"
              className="hidden"
              ref={visualRef}
              disabled={isLoading}
              onChange={(e) => {
                const files = e.target.files || new FileList();
                const file = files[0];

                if (!file) {
                  setVisual({
                    ...visual,
                    data: '',
                    name: 'No visual chosen',
                  });
                } else if (!isVisualFileType(file)) {
                  setVisual({
                    ...visual,
                    data: '',
                    name: 'No visual chosen',
                  });
                  setWarning('Please upload an jpeg, png, or gif.');
                } else if (!isVisualSizeOK(file)) {
                  setVisual({
                    ...visual,
                    data: '',
                    name: 'No visual chosen',
                  });
                  setWarning('File is too large - please resize image.');
                } else {
                  setVisual({
                    ...visual,
                    data: file,
                    name: file.name,
                  });
                }
              }}
            />
            <div
              className="grid gap-2 items-center"
              style={{
                height: '3rem',
                gridTemplateColumns: '8.06rem 1fr',
              }}
            >
              <Button
                title="Choose Visual"
                styleType="outline"
                onClick={(e) => {
                  e.preventDefault();
                  visualRef.current?.click();
                }}
              />
              <span
                className="cursor-default text-sm"
                style={{
                  maxHeight: '3rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: 'calc(100%-8.56rem)',
                }}
              >
                {visual.name}
              </span>
            </div>
          </>
        )}
        {pubVisual && (visual.action?.value === 'update' || visual.action?.value === 'remove') && (
        <span className="mt-1 p-3 bg-yellow-100 text-yellow-900 rounded text-sm">
          Warning: existing visual will be
          {visual.action.value === 'update' ? ' overwritten ' : ' removed '}
          when publishing.
        </span>
        )}
      </div>
    </div>
  );
};

export default EditStatement;
