import {
    useState,
    useContext,
    createContext,
    useMemo,
} from 'react';
import { useView } from 'context';
import { LevelEnum } from 'utils/helpers';
import { useQuery, useEffectDelayed } from 'utils/hooks';

import parseDisplayParams from './helpers';

const maxResultsPerPage = 20;

const initDisplayContext: Display = {
    maxResultsPerPage,
    page: 0,
    level: LevelEnum.one,
    showID: false,
    setPage() {},
    setShowID() {},
    isDropMoving: false,
    hideSubmitted: false,
    displaySignOut() {},
    setIsDropMoving() {},
    setHideSubmitted() {},
};

const DisplayContext = createContext<Display>(initDisplayContext);

export const useDisplay = () => useContext<Display>(DisplayContext);

const DisplayProvider: React.FunctionComponent = ({ children }) => {
    const { isStaging } = useView();

    const { searchParams } = useQuery();

    const { display: level } = useMemo(() => parseDisplayParams(searchParams), [searchParams]);

    const [page, setPageContextFunc] = useState<number>(0);

    const [showID, setShowID] = useState(false);

    const [isDropMoving, setIsDropMoving] = useState(false);

    const [hideSubmitted, setHideSubmitted] = useState(false);

    const displaySignOut = () => {
        setShowID(false);
        setIsDropMoving(false);
        setHideSubmitted(false);
        setPageContextFunc(0);
    };

    const setPage = (pageNumber: number, scroll: boolean = true) => {
        setPageContextFunc(pageNumber);
        if (scroll) {
            setTimeout(() => {
                window.scroll({ top: 0 });
            }, 0);
        }
    };

    useEffectDelayed(() => {
        setPageContextFunc(0);
        setHideSubmitted(false);
    }, [isStaging]);

    return (
        <DisplayContext.Provider value={{
            page,
            level,
            showID,
            isDropMoving,
            hideSubmitted,
            maxResultsPerPage,
            setPage,
            setShowID,
            displaySignOut,
            setIsDropMoving,
            setHideSubmitted,
        }}
        >
        {children}
        </DisplayContext.Provider>
    );
};

export default DisplayProvider;
