import { SpinLoader } from './Loaders';

const Button: React.FunctionComponent<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    title?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    tailwindAddon?: string;
    styleType?: ButtonStyles;
  }
> = ({
  children,
  style,
  title,
  styleType = 'default',
  isLoading = false,
  isDisabled = false,
  tailwindAddon = '',
  ...rest
}) => {
  const styleMap = {
    stage: 'text-white bg-azStage-false focus:ring-azBlue-400',
    staged: 'text-azStage-false bg-azStage-true pointer-events-none focus:ring-azBlue-400',
    delete: 'text-white bg-delete-700 hover:bg-delete-800 focus:ring-delete-300',
    publish: 'text-azBlue-700 bg-edited-lime hover:bg-edited-limeLight focus:ring-azBlue-400',
    outline: 'bg-none text-azBlue-600 border border-azBlue-600 hover:bg-azBlue-100 focus:ring-azBlue-400',
    confirm: 'text-white bg-edited-lime hover:bg-edited-limeDark focus:ring-azBlue-200',
    default: 'text-white bg-azBlue-700 hover:bg-azBlue-800 focus:ring-azBlue-500',
  };

  return (
    <button
      type="button"
      className={`
        font-semibold md:text-sm
        relative flex justify-center items-center transition-all outline-none
        active:bg-grey-900 focus:ring focus:ring-opacity-50
        disabled:opacity-40 disabled:cursor-default 
        ${styleMap[styleType]} ${tailwindAddon}
      `}
      style={{
        minWidth: '64px',
        minHeight: '2.2rem',
        ...style,
      }}
      disabled={isLoading || isDisabled}
      {...rest}
    >
      {isLoading ? <SpinLoader /> : (title ?? children)}
    </button>
  );
};

export default Button;
