import { useCallback } from 'react';
import {
  useAuth,
  useView,
  useStore,
  useSearch,
  useWebSocket,
} from 'context';

const useSignOut = () => {
  const { authSignOut } = useAuth();
  const { viewSignOut } = useView();
  const { storeSignOut } = useStore();
  const { searchSignOut } = useSearch();
  const { socketSignOut } = useWebSocket();

  const startSignOut = useCallback(() => {
    socketSignOut();
    searchSignOut();
    viewSignOut();
    storeSignOut();
    authSignOut();
    window.localStorage.setItem('hasUserSignedOut', 'true');
    window.sessionStorage.setItem('isFirstSignIn', 'true');
  }, [socketSignOut, searchSignOut, storeSignOut, authSignOut]);

  return { startSignOut };
};

export default useSignOut;
