import { lazy, Suspense } from 'react';
import { useAuth } from 'context';
import { getAzureAuthURL } from 'utils/api';
import { GridLoader } from 'components/shared';
import { Route, Switch } from 'react-router-dom';
import { useEligiblePopup } from 'utils/hooks';

const Platform = lazy(() => import('./Platform'));
const AzureSignIn = lazy(() => import('./Auth/Azure/SignIn'));
const AzureSignedOut = lazy(() => import('./Auth/Azure/SignedOut'));
const FirebaseSignIn = lazy(() => import('./Auth/Firebase'));

const PrivateRoute: React.FunctionComponent<{ path: string }> = ({
    children,
    ...rest
}) => {
    const { authSignOut, currentUser } = useAuth();

    const { isEligible, EligibilityConfirmation } = useEligiblePopup();

    const isSigningIn = window.sessionStorage.getItem('isSigningIn') === 'true';
    const isFirstSignIn = window.sessionStorage.getItem('isFirstSignIn') === 'true';
    const hasUserSignedOut = window.localStorage.getItem('hasUserSignedOut') === 'true';

    window.sessionStorage.removeItem('isSigningIn');
    window.sessionStorage.removeItem('isFirstSignIn');

    window.addEventListener('storage', () => {
        if (window.localStorage.getItem('hasUserSignedOut') === 'true') {
            window.sessionStorage.removeItem('state');
            authSignOut();
        }
    });

    const azureRedirect = async () => {
        const redirectURL = hasUserSignedOut && !isSigningIn
            ? `${window.location.origin}/signout?fsi=${isFirstSignIn}`
            : await getAzureAuthURL(isFirstSignIn);

        window.location.href = redirectURL;
    };

    const firebaseRedirect = async () => {
        window.location.href = `${window.location.origin}/signin`;
    };

    return (
        <Route
            {...rest}
            render={() => (
                currentUser
                    ? isEligible ? children : EligibilityConfirmation
                    : (() => {
                        if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
                            firebaseRedirect();
                        } else {
                            azureRedirect();
                        }

                        return <></>;
                      })())}
        />
    );
};

const App = () => (
    <Suspense fallback={<GridLoader />}>
        <Switch>
            <Route
                exact
                path="/signin"
                component={FirebaseSignIn}
            />
            <Route
                exact
                path="/signout"
                component={AzureSignedOut}
            />
            <Route
                path="/auth/:status"
                component={AzureSignIn}
            />
            <PrivateRoute path="*">
                <Platform />
            </PrivateRoute>
        </Switch>
    </Suspense>
);

export default App;
