import { ParamsEnum, LevelEnum } from './enums';

export const simpleTitleCase = (str: string) => str
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

export const defaultSearchParam = `?${ParamsEnum.display}=${LevelEnum.three}`;

export const removeControlChars = (str: string) => (
  str?.replace(/[\u0000-\u001F\u007F-\u009F]/g, '') || '' // eslint-disable-line
);

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
export const escapeRegExp = (str: string) => (
  str.replace(/[.*+?^${}()[\]\\]/g, '\\$&')
);
