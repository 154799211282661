import {
    LevelEnum,
    filterNames,
    simpleTitleCase,
    FilterCategoryEnum,
    findFiltersByReference,
} from 'utils/helpers';

import { REST_EP, unauthedRequest, errorResponseStatus } from './helpers';

export interface ExportSearch {
    isFilters: boolean;
    category: string;
    labels: string[];
}

interface PDFSupporting {
    statement: string;
    hasAdditional: boolean;
    additional: string[];
}

interface PDFNarrative {
    statement: string;
    hasSupporting: boolean;
    supporting: PDFSupporting[];
    filters: string[];
    id: string;
    published: string;
    showHR: boolean;
}

interface PDFData {
    link: string;
    level: LevelEnum;
    isLetter: boolean;
    isSearch: boolean;
    searched: Searched;
    narratives: ExportNarrative[];
}

const timetoDateString = (time: number | undefined) => {
    if (time === undefined) {
        return 'Error occured';
    }

    return (
        new Date(
            time || '',
        ).toLocaleString(undefined, {
            day: '2-digit', month: '2-digit', year: 'numeric',
        })
    );
};

export const buildSearches = (
    searched: Searched,
    filters: FiltersMap,
) => {
    const defaultFilters = [{
        uid: '',
        label: '',
        value: '',
    }];

    const filterLabels = findFiltersByReference(searched.searchValues, filters, defaultFilters);

    const searches = Object.values(FilterCategoryEnum).map((categoryName) => {
        const labels = filterLabels[categoryName].map(({ label }) => label);

        if (labels.every((l) => l)) {
            const search: ExportSearch = {
                labels,
                category: filterNames[categoryName],
                isFilters: true,
            };

            return search;
        }

        return null;
    }).filter((s) => s) as ExportSearch[];

    if (searched.keywordsPiped) {
        searches.push({
            labels: [searched.keywordsPiped.replace('|', ', ')],
            category: 'Keywords',
            isFilters: false,
        });
    }

    searches.push({
        labels: [`${simpleTitleCase(searched.dateType)} ${timetoDateString(searched.dateTime)}`],
        category: 'Publish Date',
        isFilters: false,
    });

    return searches;
};

const buildNarratives = (
    narratives: ExportNarrative[],
    level: LevelEnum,
) => (
    narratives.map((n, i) => {
        const supporting: PDFSupporting[] | null = level !== LevelEnum.one
            ? (n.oneSubs || []).map((os) => {
                const additional: string[] | null = level === LevelEnum.three
                    ? (os.twoSubs || []).map((s) => s.title)
                    : null;

                return {
                    statement: os.statement.title,
                    hasAdditional: (additional?.length || 0) > 0,
                    additional: additional || [],
                };
            })
            : null;

        return {
            statement: n.statement?.title || 'Error occured while exporting statement.',
            hasSupporting: (supporting?.length || 0) > 0,
            supporting: supporting || [],
            filters: [],
            showHR: i !== narratives.length - 1,
            id: n.uid || 'Error',
            published: timetoDateString(n.publishTime),
        } as PDFNarrative;
    })
);

const buildPDFBody = ({
    link,
    level,
    isLetter,
    isSearch,
    searched,
    narratives,
}: PDFData, filters: FiltersMap) => {
    if (!isSearch || searched.mainUID) {
        return JSON.stringify({
            link,
            isLetter,
            isSearch: false,
            searches: [],
            narratives: buildNarratives(narratives, level),
        });
    }

    return JSON.stringify({
        link,
        isLetter,
        isSearch: true,
        searches: buildSearches(searched, filters),
        narratives: buildNarratives(narratives, level),
    });
};

const dataURItoBlob = (dataURI: string) => {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i += 1) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], { type: 'application/pdf' });
};

export const makePDF = async (
    pdfData: PDFData,
    filters: FiltersMap,
    currentUser: CurrentUser,
) => {
    try {
        const res = await fetch(
          `${REST_EP}/make-pdf`,
          {
            method: 'POST',
            headers: {
                'x-auth-token': await currentUser?.getIdToken() || '',
                'x-auth-token-type': currentUser?.tokenType || '',
            },
            body: buildPDFBody(pdfData, filters),
          },
        );
        if (res.status === 401) {
            throw unauthedRequest;
        } else if (res.status !== 200) {
            throw errorResponseStatus;
        }
        const blob = dataURItoBlob(await res.text());
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `Scientific Foundation - Condensed - ${new Date().toLocaleString()}`;
        link.click();
        return {
            auth: true,
            success: true,
            message: 'Successfully created and exported PDF',
        };
      } catch (e) {
        if (e === unauthedRequest) {
            return {
                auth: false,
                success: false,
                message: '',
            };
        }
        return {
            auth: true,
            success: false,
            message: 'An error occured while creating PDF',
        };
    }
};
