import {
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';
import { GridLoader } from 'components/shared';

// Firebase lib imports
import { initializeApp } from 'firebase/app';
import {
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import {
  signOut,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from 'firebase/auth';

// Firebase config
import firebaseConfig from './firebaseConfig';

// init Firebase
initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();

interface AuthTypes {
  currentUser: CurrentUser;
  authSignIn: (email: string, password: string) => Promise<void>;
  authSignOut: () => Promise<void>;
  setCurrentUser: (value: React.SetStateAction<CurrentUser>) => void
}

const AuthContext = createContext<AuthTypes>({
  currentUser: null,
  authSignIn() { return new Promise(() => {}); },
  authSignOut() { return new Promise(() => {}); },
  setCurrentUser() {},
});

export const useAuth = () => useContext(AuthContext);

const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<CurrentUser>(null);

  const authSignIn: AuthTypes['authSignIn'] = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const authSignOut: AuthTypes['authSignOut'] = async () => {
    if (currentUser?.tokenType === '1') {
      setCurrentUser(null);
      return;
    }

    try {
      await signOut(auth);
    } catch (error) {
      setCurrentUser(null);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (!user) {
          throw new Error('No Firebase user found');
        }

        window.localStorage.removeItem('hasUserSignedOut');

        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          throw new Error("Firebase doc doesn't exist");
        }

        const userCopy = user as any;
        userCopy.tokenType = '2';
        userCopy.isAdmin = docSnap.data().isAdmin || false;
        userCopy.isEditor = docSnap.data().isEditor || false;
        userCopy.displayName = docSnap.data().name || '';

        setCurrentUser(userCopy);
      } catch (_) {
        setCurrentUser(null);
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{
      currentUser,
      authSignIn,
      authSignOut,
      setCurrentUser,
    }}
    >
      {loading ? <GridLoader /> : children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
