import {
  useMemo,
  useState,
  useContext,
  useCallback,
  createContext,
} from 'react';
import { useQuery, useEffectDelayed } from 'utils/hooks';
import { LevelEnum, FilterCategoryEnum } from 'utils/helpers';
import {
  useView,
  useStore,
  useDisplay,
} from 'context';

import {
  initSearched,
  isInDateRange,
  parseSearchParams,
  countKeywordMatches,
  sortFoundNarratives,
} from './helpers';

const initSearchResult: SearchResult = {
  found: [],
  status: 'start',
};

interface SearchContextTypes {
  searched: Searched;
  searchResult: SearchResult;
  initSearched: Searched;
  searchSignOut(): void;
}

const SearchContext = createContext<SearchContextTypes>({
  initSearched,
  searched: initSearched,
  searchResult: initSearchResult,
  searchSignOut() {},
});

export const useSearch = () => useContext(SearchContext);

const SearchProvider: React.FunctionComponent = ({ children }) => {
  const { searchParams } = useQuery();

  const { level, setPage } = useDisplay();

  const {
    filters,
    narratives,
    narrativesStaged,
  } = useStore();

  const {
    isStaging,
    isDesktopView,
  } = useView();

  const [searchResult, setSearchResult] = useState<SearchResult>(initSearchResult);

  const searched = useMemo(() => parseSearchParams(searchParams), [searchParams]);

  const searchSignOut = () => {
    setSearchResult({
      found: [],
      status: 'reset',
    });
  };

  const searchByUID = useCallback((uid: string) => {
    const narrtiveMap = isStaging ? narrativesStaged : narratives;

    const narrative = narrtiveMap.get(uid);

    if (!narrative) {
      setSearchResult({
        found: [],
        status: 'none',
      });
      return;
    }

    setSearchResult({
      found: [{
        uid,
        narrative,
        matchedFilterUIDs: [],
      }],
      status: 'found',
    });
  }, [isStaging, filters, narratives, narrativesStaged]);

  // TODO: Refactor
  const searchByTags = useCallback((searchedArg: Searched) => {
    const {
      dateTime,
      dateType,
      searchFilters,
      keywordsPiped,
    } = searchedArg;

    const narrtiveMap = isStaging ? narrativesStaged : narratives;

    const isAFilterSelected = Object.values(searchFilters)
      .map((f) => (f || [])
        .filter((
          { uid, value }:{ uid: string; value: string } = { uid: '', value: '' },
        ) => uid || value))
      .some((f) => f.length);

    // Short circit initializing re-renders
    if (!narrtiveMap.size || !filters.size) {
      setSearchResult({
        found: [],
        status: isStaging ? 'reset' : 'start',
      });
      return;
    }

    // Forcing no results on /staging -> /search path change due to router Link
    // Path change due to browser history may include filters selected or keywords piped
    // Get search results due to browser history (i.e. user clicking browser's back button)
    if (!isStaging && !isAFilterSelected && !keywordsPiped) {
      setSearchResult({
        found: [],
        status: 'reset',
      });
      return;
    }

    const filtersFound: Found = [];

    const keywordsFound: Found = [];

    const searchedFilterUIDs = Object
      .values(FilterCategoryEnum)
      .map((category) => {
        const filterCategory = filters.get(category) || [];

        const searchedValues = searchFilters[category]?.map(({ value }) => value) || [];

        return searchedValues.map((filterValue) => (
          filterCategory.find((option) => option.value === filterValue)?.uid || ''
        ));
      })
      .flat()
      .filter((uid) => uid);

    const isFilterSearch = !!searchedFilterUIDs?.length;

    const isKeywordSearch = !isStaging && keywordsPiped;

    if (isFilterSearch) {
      narrtiveMap.forEach((narrative, narrativeUID) => {
        const { filters: narrativeFilters, publishTime } = narrative;

        if (!isInDateRange(dateType, dateTime, publishTime)) {
          return;
        }

        const narrativeFilterUIDs = Object
          .values(FilterCategoryEnum)
          .map((category) => (
            narrativeFilters ? narrativeFilters[category] || [] : []
          ))
          .flat()
          .filter((uid) => uid);

        const isMatching = searchedFilterUIDs.every((uid) => (
          narrativeFilterUIDs.includes(uid)
        ));

        if (isMatching) {
          filtersFound.push({
            narrative,
            matchedFilterUIDs: searchedFilterUIDs,
            uid: narrativeUID,
          });
        }
      });
    }

    if (isStaging && !isFilterSearch) {
      narrtiveMap.forEach((narrative, narrativeUID) => {
        filtersFound.push({
          narrative,
          uid: narrativeUID,
          matchedFilterUIDs: [],
        });
      });
    }

    if (isKeywordSearch) {
      narrtiveMap.forEach((narrative, narrativeUID) => {
        const alreadyFound = filtersFound.find(({ uid: u }) => u === narrativeUID);

        if (filtersFound.length && !alreadyFound) {
          return;
        }

        if (
          !filtersFound.length
          && !isInDateRange(dateType, dateTime, narrative.publishTime)
        ) {
          return;
        }

        const titles = [narrative.statement?.title];

        if (level !== LevelEnum.one) {
          narrative.oneSubs?.forEach((os) => {
            titles.push(os.statement?.title);

            if (level === LevelEnum.three) {
              os.twoSubs.forEach((s) => titles.push(s.title));
            }
          });
        }

        const numOfKeywordMatches = countKeywordMatches(titles, keywordsPiped);

        if (numOfKeywordMatches > 0) {
          keywordsFound.push({
            narrative,
            uid: narrativeUID,
            matchedFilterUIDs: alreadyFound?.matchedFilterUIDs || [],
          });
        }
      });
    }

    const foundNarratives = isKeywordSearch ? keywordsFound : filtersFound;

    setSearchResult({
      found: sortFoundNarratives(foundNarratives, filters),
      status: foundNarratives.length ? 'found' : 'none',
    });
  }, [
    level,
    filters,
    searched,
    isStaging,
    narratives,
    narrativesStaged,
  ]);

  const runSearch = useCallback((
    searchedArg: Searched,
    isUseEffect: boolean = false,
  ) => {
    const { mainUID } = searchedArg;

    if (mainUID) {
      searchByUID(mainUID);
    } else {
      searchByTags(searchedArg);
    }

    if (!isUseEffect) {
      setPage(0, isDesktopView);
    }
  }, [
    level,
    filters,
    searched,
    isStaging,
    narratives,
    narrativesStaged,
  ]);

  useEffectDelayed(() => {
    runSearch(searched, true);
  }, [
    level,
    filters,
    isStaging,
    narratives,
    narrativesStaged,
  ]);

  useEffectDelayed(() => {
    runSearch(searched);
  }, [searched]);

  return (
    <SearchContext.Provider value={{
      searched,
      searchResult,
      initSearched,
      searchSignOut,
    }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
