import { useNotification } from 'utils/hooks';
import info_icon from 'assets/images/shared/info-icon.svg';

const InfoIcon: React.FunctionComponent<
    React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > & {
        message?: string;
        className?: string;
        notificationWidth?: string;
    }
> = ({
    message,
    className,
    notificationWidth,
    ...rest
}) => {
    const { notification, openNotification } = useNotification();

    return (
        <button
            type="button"
            onClick={() => {
                openNotification(
                    message,
                    {
                        width: notificationWidth,
                    },
                );
            }}
            className={`relative ${className}`}
            {...rest}
        >
            <img
                width={11}
                height={11}
                src={info_icon}
                alt="Icon for information popup"
            />
            {notification}
        </button>
    );
};

export default InfoIcon;
