import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';
import { useView } from 'context';
import FocusTrap from 'focus-trap-react';
import { useLocation } from 'react-router-dom';

import close_icon_bgwhite from 'assets/images/shared/close-icon-bgwhite.svg';

import { hideBodyScrollBar, showBodyScrollBar } from './helpers';

interface ModalWrapperProps {
  title?: string;
  width?: string;
  banner?: JSX.Element;
  isYCenter?: boolean;
  titleSize?: string;
  titlePadding?: string;
  closeCallback?: () => void;
  childrenPadding?: string;
  showTitleUnderline?: boolean;
  enableWrapperClickClose?: boolean;
}

type ModalWrapperTypes = React.FunctionComponent<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & ModalWrapperProps>;

interface ModalContextTypes {
  Modal: JSX.Element | null;
  openModal: (modal: JSX.Element) => void;
  closeModal: () => void;
  ModalWrapper: ModalWrapperTypes;
  isModalLoading: boolean;
  setIsModalLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClosedModal = () => <></>;

const ModalContext = createContext<ModalContextTypes>({
  Modal: <ClosedModal />,
  openModal() {},
  closeModal() {},
  ModalWrapper() { return <></>; },
  isModalLoading: false,
  setIsModalLoading() {},
});

export const useModal = () => useContext(ModalContext);

const ModalProvider: React.FunctionComponent = ({ children }) => {
  const { search, pathname } = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isModalLoading, setIsModalLoading] = useState(false);

  const [Modal, setModal] = useState<JSX.Element | null>(null);

  const { isStaging, isDesktopView } = useView();

  const closeModal = () => {
    setIsModalLoading(false);
    setIsModalOpen(false);
  };

  const openModal = (modal: JSX.Element) => {
    setModal(modal);
    if (!isModalOpen) {
      hideBodyScrollBar();
    }
    setIsModalOpen(true);
  };

  const ModalWrapper: ModalWrapperTypes = useCallback(({
    style,
    children: wrapperChildren,
    title = '',
    width = 'md:w-3/4 lg:w-3/4 xl:w-240',
    banner = <></>,
    isYCenter = false,
    closeCallback = () => {},
    titleSize = 'text-lg',
    titlePadding = 'px-12 md:px-22',
    childrenPadding = 'px-12 md:px-22',
    showTitleUnderline = false,
    enableWrapperClickClose = true,
    ...rest
  }) => (
    <FocusTrap>
      <div
        id="modalWrapper"
        role="grid"
        tabIndex={0}
        className="min-w-screen min-h-screen overflow-y-scroll fixed flex justify-center inset-0 outline-none bg-stone-900 bg-opacity-50"
        style={{
          zIndex: 100,
          ...style,
        }}
        onClick={(e) => {
          const target = e.target as HTMLElement;
          if (enableWrapperClickClose && target.id === 'modalWrapper') {
            closeCallback();
            setIsModalOpen(false);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            closeCallback();
            setIsModalOpen(false);
          }
        }}
        {...rest}
      >
        <div
          className={`
            z-10 py-14 my-auto 
            max-w-screen-lg min-w-xs w-full
            relative flex flex-col 
            bg-white select-border
            ${isYCenter ? 'md:-top-10' : 'md:my-10 md:self-start'}            
            ${width}
          `}
        >
          <button
            type="button"
            title="Close"
            className="z-20 absolute top-5 md:top-9 right-5 md:right-9"
            onClick={() => {
              closeCallback();
              setIsModalOpen(false);
            }}
          >
            <img
              width={32}
              height={32}
              src={close_icon_bgwhite}
              alt="Icon for closing modal"
            />
          </button>
          {title && (
            <span
              className={`
                mb-10 font-bold Lexia_Bold text-azBlue-700
                ${titleSize}
                ${titlePadding}
                ${isStaging && !isDesktopView && 'pt-1'}
                ${showTitleUnderline ? 'flex flex-col gap-5' : ''}
              `}
            >
              {title}
              {showTitleUnderline
                ? (
                  <span
                    className="w-12"
                    style={{ borderTop: 'thin solid #00365F' }}
                  />
                )
                : <></>}
            </span>
          )}
          {banner}
          <div className={`${childrenPadding}`}>
            {wrapperChildren}
          </div>
        </div>
      </div>
    </FocusTrap>
  ), [setIsModalOpen, isStaging, isDesktopView]);

  useEffect(() => {
    if (!isModalOpen && !isModalLoading) {
      showBodyScrollBar();
      setModal(null);
    }
  }, [isModalOpen, isModalLoading, setModal]);

  useEffect(() => {
    closeModal();
  }, [search, pathname]);

  return (
    <ModalContext.Provider value={{
      Modal,
      ModalWrapper,
      isModalLoading,
      openModal,
      closeModal,
      setIsModalLoading,
    }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
