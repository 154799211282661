import az_logo_footer from 'assets/images/logo/footer.png';

interface Props {
    padding?: string;
    tailwindAddon?: string;
}

const Footer = ({
    padding,
    tailwindAddon,
}: Props) => (
    <div
        className={`w-full flex flex-col text-xs text-azBlue-500 ${tailwindAddon}`}
        style={{ padding }}
    >
        <a
            target="_blank"
            rel="noreferrer"
            href="https://www.astrazeneca.com/"
            style={{ width: '150px' }}
        >
            <img
                width={150}
                height={40}
                src={az_logo_footer}
                className="mb-1.5"
                alt="AstraZeneca logo"
            />
        </a>
        <div
            className="flex flex-col gap-2 lg:gap-6 justify-between pt-1 lg:flex-row"
            style={{ borderTop: 'thin dashed #D1D5DB' }}
        >
            <div className="flex flex-col gap-2 lg:max-w-lg">
                <p>
                    This website is intended for the AstraZeneca Medical Affairs team only.
                    The scientific statements are intended for internal use only.
                    You should obtain appropriate permissions prior to using any of the
                    statements externally.
                </p>
                <span>
                    {`©AstraZeneca ${new Date().getFullYear()}`}
                </span>
            </div>
            <span className="flex gap-2 flex-wrap content-start">
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/global/en/amp-form.html"
                >
                    Report adverse events
                </a>
                <span className="text-azBlue-200">•</span>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.astrazeneca.com/legal-notice-and-terms-of-use.html"
                >
                    Legal terms of use
                </a>
                <span className="text-azBlue-200">•</span>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.globalprivacy.astrazeneca.com/"
                >
                    Privacy policy
                </a>
                <span className="text-azBlue-200">•</span>
                <a
                    href="//policy.cookiereports.com/a9c5f34f-en-gb.html"
                    className="CookieReportsLink"
                >
                    Cookie policy
                </a>
                <span className="text-azBlue-200">•</span>
                <a
                    rel="noreferrer"
                    target="_blank"
                    href="mailto:AZScientificfoundation@3storieshigh.io"
                >
                    Contact us
                </a>
            </span>
        </div>
    </div>
);

export default Footer;
