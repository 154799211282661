import { useState } from 'react';
import { useSignOut } from 'utils/hooks';
import {
    makePDF,
    ExportSearch,
    buildSearches,
} from 'utils/api';
import {
    useAuth,
    useStore,
    useModal,
    useSearch,
    useDisplay,
} from 'context';
import {
    Button,
    SingleSelect,
} from 'components/shared';

interface Props {
    uid?: string;
    type: 'search' | 'individual';
    narrative?: Partial<Narrative<Statement | StatementStaged>>;
    setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>;
    openNotification: (newMessage: string, style?: NotificationStyles) => void;
}

const fileTypes = [
    {
        uid: 'N/A',
        label: 'PowerPoint (expanded)',
        value: 'pptx',
    }, {
        uid: 'N/A',
        label: 'PDF (condensed)',
        value: 'pdf',
    },
];

const layouts = [
    {
        uid: 'N/A',
        label: 'A4 (210 by 297 mm)',
        value: 'a4',
    },
    {
        uid: 'N/A',
        label: 'Letter (8.5 by 11 inches)',
        value: 'letter',
    },
];

const Export = ({
    uid,
    type,
    narrative,
    setIsDownloading,
    openNotification,
}: Props) => {
    const { filters } = useStore();

    const { currentUser } = useAuth();

    const { startSignOut } = useSignOut();

    const { searched, searchResult } = useSearch();

    const { closeModal, ModalWrapper } = useModal();

    const { page, level, maxResultsPerPage } = useDisplay();

    const [layout, setLayout] = useState<OptionType | null>(layouts[0]);

    const [fileType, setFileType] = useState<OptionType | null>(fileTypes[0]);

    const exportNarratives: ExportNarrative[] = [];

    const exportSearches: ExportSearch[] = [];

    let link = window.location.href;

    if (type === 'search') {
        const sliceStart = page * maxResultsPerPage;

        const sliceEnd = sliceStart + maxResultsPerPage;

        exportNarratives.push(
            ...searchResult.found
                .slice(sliceStart, sliceEnd)
                .map(({ uid: foundNarrativeUID, narrative: foundNarrative }) => ({
                    ...foundNarrative,
                    uid: foundNarrativeUID,
                })),
        );

        exportSearches.push(...buildSearches(searched, filters));
    } else if (type === 'individual' && uid && narrative) {
        const [baseURL] = link.split('?');

        link = `${baseURL}?display=${level}&uid=${uid}`;

        exportNarratives.push({ ...narrative, uid });
    }

    if (!exportNarratives.length) return <></>;

    return (
        <ModalWrapper width="md:w-128" title="Export">
            <div className="grid gap-8">
                <div className="grid gap-1">
                    <label
                        htmlFor="export-type"
                        className="font-bold text-azBlue-700"
                    >
                        File Type
                    </label>
                    <SingleSelect
                        innerProps={{
                            inputId: 'export-type',
                            defaultValue: fileType,
                            onChange: setFileType,
                            options: fileTypes,
                        }}
                    />
                </div>
                {fileType?.value === 'pdf' && (
                    <div className="grid gap-1">
                        <label
                            htmlFor="export-layout"
                            className="font-bold text-azBlue-700"
                        >
                            Page Layout
                        </label>
                        <SingleSelect
                            innerProps={{
                                inputId: 'export-layout',
                                defaultValue: layout,
                                onChange: setLayout,
                                options: layouts,
                            }}
                        />
                    </div>
                )}
                <Button
                    title="Download"
                    tailwindAddon="w-full"
                    onClick={async () => {
                        closeModal();

                        setIsDownloading(true);

                        let notificationMsg = '';

                        if (fileType?.value === 'pptx') {
                            const { makePPTX } = await import('utils/pptx');
                            const { message } = await makePPTX({
                                link,
                                level,
                                searches: exportSearches,
                                narratives: exportNarratives,
                            });
                            notificationMsg = message;
                        } else {
                            const { auth, message } = await makePDF(
                                {
                                    link,
                                    level,
                                    searched,
                                    isLetter: layout?.value === 'letter',
                                    isSearch: type === 'search',
                                    narratives: exportNarratives,
                                },
                                filters,
                                currentUser,
                            );
                            if (!auth) {
                                setIsDownloading(false);
                                startSignOut();
                            }
                            notificationMsg = message;
                        }

                        if (type === 'search') {
                            openNotification(
                                notificationMsg,
                                {
                                    right: -30,
                                    parentWidth: 35,
                                },
                            );
                        } else {
                            openNotification(notificationMsg);
                        }

                        setIsDownloading(false);
                    }}
                />
            </div>
        </ModalWrapper>
    );
};

export default Export;
