export const S3_EP = process.env.REACT_APP_S3_EP || '';

export const WS_EP = process.env.REACT_APP_WS_EP || '';

export const REST_EP = process.env.REACT_APP_REST_EP || '';

export const AUTH_EP = process.env.REACT_APP_AUTH_EP || '';

export const unauthedRequest = new Error('401');

export const errorResponseStatus = new Error('did not recieve a successful response status');
