import { useMemo, useCallback } from 'react';
import { useStore } from 'context';
import { FilterCategoryEnum } from 'utils/helpers';

const getRandomChar = (charList: string) => {
    const randomIndex = Math.floor(Math.random() * charList.length);

    return charList[randomIndex];
};

const getRandomChars = (length: number) => {
    const alphaNumericList = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    return [...new Array(length)].map(() => getRandomChar(alphaNumericList)).join('');
};

const useRandomValues = () => {
    const { filters } = useStore();

    const existingFilterValues = useMemo(() => {
        const filterValues: string[] = [];

        Object.keys(FilterCategoryEnum).forEach((categoryName) => {
            const category = filters.get(categoryName as FilterCategoryEnum);

            category?.forEach(({ value }) => {
                filterValues.push(value);
            });
        });

        return filterValues;
    }, [filters]);

    const getRandomFilterParam = useCallback((length: number) => {
        for (let i = 0; i < 1000; i += 1) {
            const paramValue = getRandomChars(length);

            if (!existingFilterValues.includes(paramValue)) {
                return paramValue;
            }
        }

        throw new Error('could not find a unique param value');
    }, [existingFilterValues]);

    return { getRandomFilterParam };
};

export default useRandomValues;
