export const moveItem = (
    array: any[] | undefined,
    newIndex: number,
    prevIndex: number | null,
    item: any,
) => {
    const arrayCopy = [...array || []];

    if (newIndex === prevIndex) {
        arrayCopy[newIndex] = item;
        return arrayCopy;
    }

    if (prevIndex !== null) {
        arrayCopy.splice(prevIndex, 1);
    }

    arrayCopy.splice(newIndex, 0, item);

    return arrayCopy;
};

export const isShallowMatch = (arrayA: any[], arrayB: any[]) => (
    arrayA.length === arrayB.length
    && arrayA.every((item) => arrayB.includes(item))
);
