import React from 'react';

export const defaultFocus = 'focus:border-azPurple-500 focus:ring-1 focus:ring-azPurple-500';

const Input: React.FunctionComponent<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    innerRef?: React.LegacyRef<HTMLInputElement>;
    isDisabled?: boolean;
  }
> = ({
  style,
  innerRef,
  isDisabled,
  ...rest
}) => {
  const classColors = isDisabled
    ? 'border-inputBorder-300 bg-inputBorder-200'
    : 'border-azBlue-700';
  return (
    <input
      className={`w-full border focus:ring-2 text-base md:text-sm focus:border-white focus:ring-azBlue-700 focus:outline-none ${classColors}`}
      style={{
        minHeight: '32px',
        padding: '2px 6px',
        ...style,
      }}
      ref={innerRef}
      disabled={isDisabled}
      {...rest}
    />
  );
};

export default Input;
