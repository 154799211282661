import { AUTH_EP } from './helpers';

const authURL = 'https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314/oauth2/v2.0';
const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
const redirectDomain = process.env.REACT_APP_OAUTH_REDIRECT_DOMAIN;
const redirectURI = encodeURIComponent(`${redirectDomain}/auth/callback`);

const generateCodeVerifer = () => {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let text = '';

    for (let i = 0; i < 43; i += 1) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
};

const generateCodeChallenge = async (codeVerifier: string) => {
    const digest = await crypto.subtle.digest('SHA-256',
      new TextEncoder().encode(codeVerifier));

    return window.btoa(String.fromCharCode(...new Uint8Array(digest)))
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
};

export const getAzureAuthURL = async (isFirstSignIn: boolean) => {
    const path = window.location.pathname;
    const search = window.location?.search || '';
    const codeVerifier = generateCodeVerifer();
    const codeChallenage = await generateCodeChallenge(codeVerifier);
    const params = [
        'scope=openid%20profile%20api%3A%2F%2F122560fc.astrazeneca.net%2Fuser_impersonation',
        'response_type=code',
        'response_mode=query',
        'code_challenge_method=S256',
        `client_id=${clientId}`,
        `redirect_uri=${redirectURI}`,
        `code_challenge=${codeChallenage}`,
    ];

    window.sessionStorage.setItem('state', isFirstSignIn ? '/' : `${path}${search}`);
    window.sessionStorage.setItem('code_verifier', codeVerifier);

    return `${authURL}/authorize?${params.join('&')}`;
};

export const getAzureToken = async (code: string, grantType: 'authorization_code' | 'refresh_token') => {
    try {
        const isAuthCode = grantType === 'authorization_code';
        const codeParam = isAuthCode ? 'code' : 'refresh_token';
        const params = [
            'scope=openid%20offline_access%20profile%20api%3A%2F%2F122560fc.astrazeneca.net%2Fuser_impersonation',
            `grant_type=${grantType}`,
            `${codeParam}=${code}`,
            `client_id=${clientId}`,
            `redirect_uri=${redirectURI}`,
        ];

        if (isAuthCode) {
            const codeVerifer = window.sessionStorage.getItem('code_verifier');

            params.push(`code_verifier=${codeVerifer}`);

            window.sessionStorage.removeItem('code_verifier');
        }

        const res = await fetch(
            `${AUTH_EP}/token`,
            {
                method: 'POST',
                body: JSON.stringify({ params: params.join('&') }),
            },
        );

        return await res.json();
    } catch {
        return { error: 'fetch failure' };
    }
};
