import { useState, useEffect } from 'react';
import { useStore, useSearch } from 'context';
import { FilterCategoryEnum } from 'utils/helpers';

const useDisplayFilters = (
    filterReferences: FilterReferences | undefined,
    matchedFilterUIDs: UID[],
) => {
    const { filters } = useStore();

    const { searchResult } = useSearch();

    const [shownFilters, setShownFilters] = useState<ParsedCategory[]>([]);

    const [hiddenFilters, setHiddenFilters] = useState<ParsedCategory[]>([]);

    const parseFilter = (categoryName: FilterCategoryEnum) => {
        if (!filterReferences) return [];

        const category = filters.get(categoryName) || [];

        const narrativeUIDs = filterReferences[categoryName] || [];

        return narrativeUIDs.map((uid) => {
            const filter = category.find((c) => c.uid === uid);

            return {
                uid: filter?.uid || 'Error',
                label: filter?.label || 'Error',
                value: filter?.value || 'Error',
                highlight: matchedFilterUIDs.includes(uid),
            };
        });
    };

    useEffect(() => {
        const sf: ParsedCategory[] = [];

        const hf: ParsedCategory[] = [];

        Object
            .values(FilterCategoryEnum)
            .map((key) => parseFilter(key))
            .flat()
            .forEach((tag) => {
                if (tag.highlight) {
                    sf.push(tag);
                } else {
                    hf.push(tag);
                }
            });

        setShownFilters(sf);
        setHiddenFilters(hf);
    }, [searchResult.found, filters, filterReferences]);

    return { shownFilters, hiddenFilters };
};

export default useDisplayFilters;
