import { FilterCategoryEnum } from 'utils/helpers';

export const filterNames: { [key: string]: string } = {
    [FilterCategoryEnum.drugs]: 'Drug',
    [FilterCategoryEnum.trials]: 'Trial',
    [FilterCategoryEnum.section]: 'T3 Section',
    [FilterCategoryEnum.tumours]: 'Tumour Type',
    [FilterCategoryEnum.settings]: 'Setting',
    [FilterCategoryEnum.elements]: 'Area of Interest',
    [FilterCategoryEnum.overviews]: 'Overview',
    [FilterCategoryEnum.biomarkers]: 'Biomarker',
};

const baseFilters = {
    [FilterCategoryEnum.drugs]: [],
    [FilterCategoryEnum.trials]: [],
    [FilterCategoryEnum.section]: [],
    [FilterCategoryEnum.tumours]: [],
    [FilterCategoryEnum.settings]: [],
    [FilterCategoryEnum.elements]: [],
    [FilterCategoryEnum.overviews]: [],
    [FilterCategoryEnum.biomarkers]: [],
};

export const initFilters: Filters = { ...baseFilters };

export const initFilterRefrences: FilterReferences = { ...baseFilters };

export const findFiltersByReference = (
    filterReferences: FilterReferences | undefined,
    filtersMap: FiltersMap,
    defaultOption: MultiOptions,
) => {
    const foundDefaults: Filters = { ...initFilters };

    Object.values(FilterCategoryEnum).forEach((categoryName) => {
        const category = filtersMap.get(categoryName) || [];

        const defaultCategory: OptionType[] = [];

        if (filterReferences) {
            const providedCategory = filterReferences[categoryName] || [];

            category.forEach((c) => {
                if (providedCategory.includes(c.uid) || providedCategory.includes(c.value)) {
                    defaultCategory.push(c);
                }
            });
        }

        foundDefaults[categoryName] = defaultCategory?.length ? defaultCategory : defaultOption;
    });

    return foundDefaults;
};
