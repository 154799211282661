import { Footer, Header } from 'components/shared';

import slide_1 from 'assets/images/landing/carousel/slide-1.webp';

const PopupWrapper = ({ children }: { children: React.ReactNode; }) => (
    <div className="relative py-8 h-screen flex flex-col">
        <Header tailwindAddon="px-6 md:px-12" />
        <div
            className="bg-right xl:bg-center mb-10"
            style={{
                height: '44.5rem',
                backgroundSize: 'cover',
                backgroundImage: `url(${slide_1})`,
                backgroundRepeat: 'no-repeat',
            }}
        />
        <div className="fixed right-0 left-0 top-0 bottom-0 flex justify-center items-center bg-stone-900 bg-opacity-50">
            <div className="w-full max-w-xl -mt-20 bg-white text-azBlue-700 select-border">
                {children}
            </div>
        </div>
        <Footer tailwindAddon="px-6 md:px-12 mt-auto" />
    </div>
);

export default PopupWrapper;
