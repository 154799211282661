import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSignOut, useOnline } from 'utils/hooks';
import { unstable_batchedUpdates } from 'react-dom';
import {
  useAuth,
  useStore,
} from 'context';
import {
  WS_EP,
  getDBData,
  unauthedRequest,
  CategoriesParam,
} from 'utils/api';

import { updateMap, ReplyReciever } from './helpers';

interface WSContextType {
  storeHasInit: boolean;
  filtersHasInit: boolean;
  socketSignOut: ()=>void;
  sendData: DataSender;
}

const WSContext = createContext<WSContextType>({
  storeHasInit: false,
  filtersHasInit: false,
  sendData() { return new Promise((r) => r()); },
  socketSignOut() {},
});

export const useWebSocket = () => useContext(WSContext);

const LAMBDA_TIMEOUT = 10000;

const WSProvider: React.FunctionComponent = ({ children }) => {
  const { startSignOut } = useSignOut();
  const { currentUser } = useAuth();
  const {
    filters,
    narratives,
    narrativesStaged,
    setFilters,
    setNarratives,
    setNarrativesStaged,
  } = useStore();
  const { isOnline } = useOnline();

  const pongReciever = useRef(new ReplyReciever());
  const authReciever = useRef(new ReplyReciever());
  const batchReciever = useRef(new ReplyReciever());

  const queuedMessage = useRef<string | null>(null);

  const wsInit = useMemo(() => new WebSocket(WS_EP), []);
  const [socket, setSocket] = useState(wsInit);

  const [storeHasInit, setStoreHasInit] = useState(false);
  const [filtersHasInit, setFiltersHasInit] = useState(false);

  const getReciver = (action: ClientMsg['action']) => (
      action === 'ping' ? pongReciever
    : action === 'auth' ? authReciever
    : batchReciever
  );

  const socketSignOut = useCallback(() => {
    socket.close(4999);
  }, [socket]);

  const makeClientMsg = useCallback(async (
    action: ClientMsg['action'],
    data: ClientMsg['data'] = {},
  ) => {
    const clientMsg: ClientMsg = {
      data,
      action,
      token: await currentUser?.getIdToken() || '',
      sender: getReciver(action).current.setID(uuidv4()),
      sentAt: new Date().getTime(),
      tokenType: currentUser?.tokenType || '1',
    };

    return JSON.stringify(clientMsg);
  }, [currentUser]);

  const replyWaiter = useCallback((action: ClientMsg['action']) => (
    new Promise<void>((resolve, reject) => {
      const waiter = (count: number) => {
        if (count >= LAMBDA_TIMEOUT) {
          return reject(new Error('waiter exceeding lambda timeout'));
        }

        if (getReciver(action).current.hasRecieved()) {
          return resolve();
        }

        setTimeout(() => waiter(count + 50), 50);
        return undefined;
      };

      waiter(0);
    })
  ), []);

  const handleBatchData = useCallback(async (batchData: ServerMsg['data']) => {
    const { set, del } = batchData || {};

    const [
      newFilters,
      newNarratives,
      newNarrativesStaged,
    ] = [
      updateMap(set?.filters, del?.filters, filters),
      updateMap(set?.narratives, del?.narratives, narratives),
      updateMap(set?.narrativesStaged, del?.narrativesStaged, narrativesStaged),
    ];

    unstable_batchedUpdates(() => {
      if (newFilters) setFilters(newFilters);
      if (newNarratives) setNarratives(newNarratives);
      if (newNarrativesStaged) setNarrativesStaged(newNarrativesStaged);
    });

    if (newFilters) setFiltersHasInit(true);
  }, [filters, narratives, narrativesStaged]);

  const fetchAndLoadData = useCallback(async () => {
    try {
      if (!currentUser) throw unauthedRequest;

      const getData = async (keyGroup: CategoriesParam) => {
        const batchData: BatchData = {
          set: await getDBData(keyGroup, currentUser),
          del: {},
        };

        handleBatchData(batchData);
      };

      if (currentUser.isAdmin || currentUser.isEditor) {
        await Promise.all(
          storeHasInit
            ? [getData(['filters', 'published', 'staged'])]
            : [getData(['filters']), getData(['published', 'staged'])],
        );
      } else {
        await Promise.all(
          storeHasInit
            ? [getData(['filters', 'published'])]
            : [getData(['filters']), getData(['published'])],
        );
      }
      setStoreHasInit(true);
    } catch (error) {
      if (error === unauthedRequest) {
        startSignOut();
      }
    }
  }, [currentUser, storeHasInit]);

  socket.onmessage = ({ data: msgData }: MessageEvent<any>) => {
    try {
      const msg: ServerMsg = JSON.parse(msgData) || {};

      if (!msg.isAuth) throw unauthedRequest;

      switch (msg?.action) {
        case 'pong':
          pongReciever.current.recieve(msg?.sender);
          break;
        case 'auth':
          authReciever.current.recieve(msg?.sender);
          break;
        case 'batch':
          batchReciever.current.recieve(msg?.sender);
          handleBatchData(msg?.data);
          break;
        default:
          return;
      }
    } catch (error) {
      if (error === unauthedRequest) {
        startSignOut();
      }
    }
  };

  socket.onclose = (event: CloseEvent) => {
    if (event.code === 4999) return;
    const wsInterval = setInterval(() => {
      if (navigator.onLine && socket.readyState === 3) {
        setSocket(new WebSocket(WS_EP));
        clearInterval(wsInterval);
      }
    }, 500);
  };

  socket.onerror = () => socket.close();

  socket.onopen = async () => {
    socket.send(await makeClientMsg('auth'));

    if (queuedMessage.current) {
      socket.send(queuedMessage.current);
    }

    if (storeHasInit) {
      fetchAndLoadData();
    }

    try {
      await replyWaiter('auth');
      if (queuedMessage.current) {
        await replyWaiter('batch');
        queuedMessage.current = null;
      }
    } catch {
      startSignOut();
    }
  };

  const sendData = useCallback(async (batchData: BatchData) => {
    if (!navigator.onLine) {
      throw new Error('no internet connection');
    }

    const message = await makeClientMsg('batch', batchData);

    if (socket.readyState !== 1) {
      queuedMessage.current = message;
      return;
    }

    socket.send(message);

    await replyWaiter('batch');
  }, [replyWaiter, socket]);

  useEffect(() => {
    const pingInterval = setInterval(async () => {
      if (!navigator.onLine || socket.readyState !== 1) {
        return;
      }

      socket.send(await makeClientMsg('ping'));

      try {
        await replyWaiter('ping');
      } catch {} // eslint-disable-line
    }, 60000 * 5);

    return () => clearInterval(pingInterval);
  }, [replyWaiter, socket]);

  useEffect(() => {
    if (!isOnline) {
      socket.close();
    }
  }, [socket, isOnline]);

  useEffect(() => {
    fetchAndLoadData();
  }, []);

  return (
    <WSContext.Provider
      value={{
        storeHasInit,
        filtersHasInit,
        sendData,
        socketSignOut,
      }}
    >
      {children}
    </WSContext.Provider>
  );
};

export default WSProvider;
