interface Props {
  message: string | JSX.Element;
  padding?: string;
}

export const FormError = ({ message, padding = 'px-4 md:px-14' }: Props) => (
  <div className={`${padding}`}>
    <div className="w-full p-8 flex bg-delete-300 text-delete-700">
      {message}
    </div>
  </div>
);

export const FormSuccess = ({ message, padding = 'px-4 md:px-14' }: Props) => (
  <div className={`${padding}`}>
    <div className="w-full p-8 flex bg-edited-yellow text-azBlue-700">
      {message}
    </div>
  </div>
);

export const FormWarning = ({ message, padding = '-mt-7 mb-5 px-7 md:px-17' }: Props) => (
  <div
    className={`${padding}`}
    style={{ display: message ? 'block' : 'none' }}
  >
    <div className="w-full py-2 px-5 flex md:text-sm bg-edited-yellow text-azBlue-700 font-bold">
      {message}
    </div>
  </div>
);

interface FormMessagesProps {
  error: string;
  success: string;
  warning: string;
}

export const FormMessages = ({
  error,
  success,
  warning,
}: FormMessagesProps) => (
  error ? (
    <FormError message={error} />
  ) : success ? (
    <FormSuccess message={success} />
  ) : (
    <FormWarning message={warning} />
  )
);
