import { CSSProperties, useMemo, useState } from 'react';
import { Button, PopupWrapper } from 'components/shared';

const useEligiblePopup = () => {
    const initEligibilityStatus = window.localStorage.getItem('hasUserConfirmedEligibility') === 'true';

    const [isEligible, setIsEligible] = useState(initEligibilityStatus);

    const buttonStyle: CSSProperties = {
        width: '100%',
        padding: '10px',
        minHeight: '55px',
    };

    const EligibilityConfirmation = useMemo(() => (
        <PopupWrapper>
            <div className="p-10 md:p-16">
                <p>
                    This website, and the content within, is only appropriate for
                    individuals in the Medical Affairs teams at AstraZeneca. Please
                    confirm if you are therefore eligible to enter the platform.
                </p>
                <div className="mt-7 flex flex-col gap-7">
                    <Button
                        title="I am not eligible"
                        styleType="outline"
                        style={buttonStyle}
                        onClick={() => {
                            window.localStorage.removeItem('hasUserConfirmedEligibility');
                            window.location.href = 'https://www.astrazeneca.com/';
                        }}
                    />
                    <Button
                        title="I confirm that I am eligible"
                        style={buttonStyle}
                        onClick={() => {
                            window.localStorage.setItem('hasUserConfirmedEligibility', 'true');
                            setIsEligible(true);
                        }}
                    />
                </div>
            </div>
        </PopupWrapper>
    ), []);

    return { isEligible, EligibilityConfirmation };
};

export default useEligiblePopup;
