import az_logo_footer from 'assets/images/logo/footer.png';

interface Props {
    padding?: string;
    tailwindAddon?: string;
}

const Header = ({
    padding,
    tailwindAddon,
}: Props) => (
    <div
        className={`grid justify-end items-center ${tailwindAddon}`}
        style={{ padding }}
    >
        <a
            href="https://www.astrazeneca.com/"
            target="_blank"
            rel="noreferrer"
        >
            <img
                width={190}
                height={51}
                src={az_logo_footer}
                className="md:hidden mb-8"
                alt="AstraZeneca logo"
            />
            <img
                width={170}
                height={45}
                src={az_logo_footer}
                className="hidden md:block mb-4"
                alt="AstraZeneca logo"
            />
        </a>
    </div>
);

export default Header;
