export class BatchMessage implements BatchMessageTypes {
  #set = {};

  #del = {};

  async sender(batchData: BatchData) {
    this.#set = batchData.set;
    this.#del = batchData.del;
    return Promise.resolve();
  }

  constructor(sender: DataSender, item?: SetItem) {
    if (item) {
      this.setItem(item);
    }
    this.sender = sender;
  }

  setItem({ key, field, value }: SetItem) {
    const set = { ...this.#set } as BatchSet;

    this.#set = {
      ...this.#set,
      [key]: {
        ...(set[key] || {}),
        [field]: value,
      },
    };
  }

  delItem({ key, field }: DelItem) {
    const del = { ...this.#del } as BatchDel;

    this.#del = {
      ...this.#del,
      [key]: del[key]?.length ? del[key].push(field) : [field],
    };
  }

   async send() {
    return this.sender({
      set: this.#set,
      del: this.#del,
    });
  }
}

export class ReplyReciever {
  #id: string = '';

  #recieved = false;

  setID(id: string) {
    this.#id = id;
    this.#recieved = false;
    return id;
  }

  getID() {
    return this.#id;
  }

  recieve(id: string) {
    if (id === this.#id) {
      this.#recieved = true;
    }
  }

  hasRecieved() {
    return this.#recieved;
  }
}

export const updateMap = <T extends Map<any, any>>(
  setItems: { [key: string]: any } | undefined,
  delItems: string[] | undefined,
  oldMap: T,
) => {
  const setKeys = Object.keys(setItems || {});

  if (!setKeys.length && !delItems) {
    return null;
  }

  const newMap = new Map(oldMap);

  if (setKeys.length && setItems) {
    setKeys.forEach((uid) => newMap.set(uid, setItems[uid]));
  }

  if (delItems?.length) {
    delItems.forEach((uid) => newMap.delete(uid));
  }

  return newMap;
};
