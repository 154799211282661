import { REST_EP, unauthedRequest } from './helpers';

export type CategoriesParam = ('filters' | 'published' | 'staged')[];

export const getDBData = async (categories: CategoriesParam, currentUser: CurrentUser) => {
  const res = await fetch(
    `${REST_EP}/narratives?categories=${categories.join(',')}`,
    {
      method: 'GET',
      headers: {
        'x-auth-token': await currentUser?.getIdToken() || '',
        'x-auth-token-type': currentUser?.tokenType || '',
      },
    },
  );
  if (res.status === 401) throw unauthedRequest;
  return res.json();
};
