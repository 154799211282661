import clone from 'rfdc';

export const moveLevelTwoItems = (
    destinationItem: DragItem,
    sourceItem: DragItem,
    items: OneSub<Statement | StatementStaged>[],
) => {
    const itemsClone = clone()(items);

    const sourceIndex = items.findIndex(
        ({ statement }) => statement?.uid === sourceItem?.statementID,
    );

    const destinationIndex = items.findIndex(
        ({ statement }) => statement?.uid === destinationItem?.statementID,
    );

    // Unexpectedly could not find source or destination item
    if (sourceIndex === -1 || destinationIndex === -1) {
        return null;
    }

    itemsClone.splice(destinationIndex, 0, itemsClone.splice(sourceIndex, 1)[0]);

    return itemsClone;
};

const findItemLocations = (
    destinationItem: DragItem,
    sourceItem: DragItem,
    items: OneSub<Statement | StatementStaged>[],
) => {
    const indexes: {
        srcOneIndex: number | null;
        srcTwoIndex: number | null;
        desOneIndex: number;
        desTwoIndex: number;
    } = {
        srcOneIndex: null,
        srcTwoIndex: null,
        desOneIndex: destinationItem.oneSubIndex,
        desTwoIndex: 0,
    };

    // Looping through all level two statements in a single narrative.
    // Finding where the source item has hovered over (destination item).
    items.forEach(({ twoSubs }, oneIndex) => {
        twoSubs?.forEach(({ uid }, twoIndex) => {
            if (uid === sourceItem.statementID) {
                indexes.srcOneIndex = oneIndex;
                indexes.srcTwoIndex = twoIndex;
            } else if (uid === destinationItem.statementID) {
                indexes.desOneIndex = oneIndex;
                indexes.desTwoIndex = twoIndex;
            }
        });
    });

    return indexes;
};

export const moveLevelThreeItems = (
    destinationItem: DragItem,
    sourceItem: DragItem,
    items: OneSub<Statement | StatementStaged>[],
) => {
    const {
        srcOneIndex,
        srcTwoIndex,
        desOneIndex,
        desTwoIndex,
    } = findItemLocations(destinationItem, sourceItem, items);

    // Unexpectedly could not find source item
    if (srcOneIndex === null || srcTwoIndex === null) {
        return null;
    }

    const itemsClone = clone()(items);

    const { twoSubs: sourceTwoSubs = [] } = itemsClone[srcOneIndex] || {};

    const { twoSubs: destinationTwoSubs = [] } = itemsClone[desOneIndex] || {};

    // Extracting item from source array and injecting item into destination array
    destinationTwoSubs.splice(
        desTwoIndex,
        0,
        sourceTwoSubs.splice(srcTwoIndex, 1)[0],
    );

    // Assigning spliced/mutated arrays back into level two (oneSub)
    itemsClone[srcOneIndex].twoSubs = [...sourceTwoSubs];

    itemsClone[desOneIndex].twoSubs = [...destinationTwoSubs];

    return itemsClone;
};
