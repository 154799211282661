/* eslint arrow-body-style: off */

import Select, { components } from 'react-select';

import arrow_down from 'assets/images/shared/arrow-down.svg';
import close_icon_bgwhite from 'assets/images/shared/close-icon-bgwhite.svg';

export const tagMaxWidth = 150;

const azBlue700 = '#00365f';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        width={10}
        height={5.5}
        src={arrow_down}
        alt="Icon for multi select"
      />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <img
        width={15}
        height={15}
        src={close_icon_bgwhite}
        alt="Icon for clear icon"
      />
    </components.ClearIndicator>
  );
};

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <img
        width={10}
        height={10}
        src={close_icon_bgwhite}
        alt="Icon for clear icon"
      />
    </components.MultiValueRemove>
  );
};

const styles = {
  indicatorSeparator: () => ({}),
  singleValue: (provided: any) => {
    return {
      ...provided,
      top: '55%',
      fontSize: '14px',
      fontWeight: '400',
      marginLeft: 7,
    };
  },
  loadingIndicator: (provided: any) => {
    return {
      ...provided,
      color: azBlue700,
    };
  },
  clearIndicator: (provided: any) => {
    return {
      ...provided,
      marginTop: '4px',
      borderRadius: 1,
      color: azBlue700,
      padding: 0,
      '&:hover': {
        transform: 'scale(1.2)',
      },
    };
  },
  dropdownIndicator: (provided: any) => {
    return {
      ...provided,
      marginTop: '4px',
      padding: '6px',
      color: azBlue700,
      '&:hover': {
        transform: 'scale(1.2)',
      },
    };
  },
  control: (provided: any, state: any) => {
    return {
      ...provided,
      borderWidth: 0,
      borderRadius: 0,
      backgroundColor: '',
      minHeight: 40,
      boxShadow: `0 ${state.isFocused ? '2' : '1'}px 0 0 ${azBlue700}`,
      '&:hover': {
        cursor: 'pointer',
        borderColor: null,
      },
    };
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      cursor: 'pointer',
      fontSize: '14px',
      color: state.isFocused ? 'hsl(0, 0%, 100%)' : 'inherit',
      backgroundColor: state.isFocused ? azBlue700 : 'transparent',
      '&:active': {
        backgroundColor: azBlue700,
      },
    };
  },
  multiValue: (provided: any) => {
    return {
      ...provided,
      margin: '0 4px 0 0',
      padding: 0,
      backgroundColor: '#FFFFFF',
      border: 'thin solid #00365f',
      borderRadius: '5px',
      maxWidth: tagMaxWidth,
    };
  },
  multiValueLabel: (provided: any) => {
    return {
      ...provided,
      fontSize: '12px',
      color: '#00365F',
    };
  },
  multiValueRemove: (provided: any) => {
    return {
      ...provided,
      paddingLeft: 2,
      paddingRight: 2,
    };
  },
  valueContainer: (provided: any) => {
    return {
      ...provided,
      padding: '0',
    };
  },
};

export const MultiSelect = ({ innerRef, innerProps }: SelectProps<true>) => (
  <Select
    isMulti
    isClearable={false}
    className="basic-multi-select"
    classNamePrefix="select"
    styles={styles}
    components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
    ref={innerRef}
    placeholder=""
    {...innerProps}
  />
);

export const SingleSelect = ({ innerRef, innerProps }: SelectProps<false>) => (
  <Select
    className="basic-multi-select"
    classNamePrefix="select"
    styles={styles}
    components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
    ref={innerRef}
    placeholder=""
    {...innerProps}
  />
);
