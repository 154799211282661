import {
    useRef,
    useEffect,
    EffectCallback,
    DependencyList,
} from 'react';

const useEffectDelayed = (
    effectCallback: EffectCallback,
    deps: DependencyList | undefined,
) => {
    const hookHasMounted = useRef(false);

    useEffect(() => {
        if (hookHasMounted.current) {
            effectCallback();
        } else {
            hookHasMounted.current = true;
        }
    }, deps);
};

export default useEffectDelayed;
