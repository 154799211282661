export enum FilterCategoryEnum {
    tumours = 'tumours',
    section = 'section',
    drugs = 'drugs',
    settings = 'settings',
    elements = 'elements',
    biomarkers = 'biomarkers',
    trials = 'trials',
    overviews = 'overviews',
}

export enum ParamsEnum {
    tumours = 'tu',
    drugs = 'dr',
    section = 'sc',
    settings = 'se',
    elements = 'el',
    biomarkers = 'bi',
    trials = 'tr',
    overviews = 'ov',
    display = 'display',
    uid = 'uid',
    dateTime = 'dateTime',
    dateType = 'dateType',
    keywords = 'keywords',
}

export enum LevelEnum {
    one = 'key',
    two = 'supporting',
    three = 'additional',
}

export enum StageActionEnum {
    new = 'new',
    remove = 'remove',
    update = 'update',
    noChange = 'noChange',
}
