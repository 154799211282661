import {
  ParamsEnum,
  initFilters,
  escapeRegExp,
  FilterCategoryEnum,
  initFilterRefrences,
} from 'utils/helpers';

export const isInDateRange = (
  dateType: string,
  dateTime: number,
  publishTime: number | undefined,
) => (
  publishTime && (
    (dateType === 'after' && publishTime >= dateTime)
    || (dateType === 'before' && publishTime <= dateTime)
  )
);

export const countKeywordMatches = (
  titles: (string | undefined)[],
  keywordsPiped: string,
) => {
  if (!keywordsPiped || !titles.length) {
    return 0;
  }

  try {
    const re = new RegExp(escapeRegExp(keywordsPiped), 'gi');

    const matches = (titles
      .join(' ')
      .match(re) || [])
      .map((match) => match.toLowerCase())
      .filter((match) => match);

    const keywords = keywordsPiped
      .split('|')
      .map((kw) => kw.toLowerCase())
      .filter((kw) => kw);

    const isEveryKeywordMatched = keywords.every((kw) => (
      matches.includes(kw)
    ));

    return isEveryKeywordMatched ? matches.length : 0;
  } catch {
    return 0;
  }
};

const categoryToQuery = {
  [FilterCategoryEnum.drugs]: ParamsEnum.drugs,
  [FilterCategoryEnum.trials]: ParamsEnum.trials,
  [FilterCategoryEnum.section]: ParamsEnum.section,
  [FilterCategoryEnum.tumours]: ParamsEnum.tumours,
  [FilterCategoryEnum.settings]: ParamsEnum.settings,
  [FilterCategoryEnum.elements]: ParamsEnum.elements,
  [FilterCategoryEnum.overviews]: ParamsEnum.overviews,
  [FilterCategoryEnum.biomarkers]: ParamsEnum.biomarkers,
};

export const initSearched: Searched = {
  mainUID: '',
  dateTime: 0,
  dateType: 'after',
  searchValues: initFilterRefrences,
  searchFilters: initFilters,
  keywordsPiped: null,
};

const parseFilterParams = (query: URLSearchParams) => {
  const searchValues: FilterReferences = { ...initFilterRefrences };

  const searchFilters: Filters = { ...initFilters };

  Object.values(FilterCategoryEnum).forEach((categoryName) => {
    const queryName = categoryToQuery[categoryName];

    const param = query.get(queryName);

    const paramSplit = param?.split('|') || [];

    searchValues[categoryName] = paramSplit;

    searchFilters[categoryName] = paramSplit.map((p) => ({
      uid: '',
      label: '',
      value: p,
    }));
  });

  return { searchValues, searchFilters };
};

export const parseSearchParams = (query: URLSearchParams): Searched => ({
  ...parseFilterParams(query),
  mainUID: query.get(ParamsEnum.uid) ?? initSearched.mainUID,
  dateTime: Number(query.get(ParamsEnum.dateTime) || '') ?? initSearched.dateTime,
  dateType: query.get(ParamsEnum.dateType) ?? initSearched.dateType,
  keywordsPiped: query.get(ParamsEnum.keywords) ?? initSearched.keywordsPiped ?? '',
});

const getSortedFiltersUIDs = (filters: FiltersMap) => {
  const categoryNamesSorted = [
    FilterCategoryEnum.section,
    FilterCategoryEnum.tumours,
    FilterCategoryEnum.elements,
    FilterCategoryEnum.drugs,
    FilterCategoryEnum.settings,
    FilterCategoryEnum.biomarkers,
    FilterCategoryEnum.trials,
    FilterCategoryEnum.overviews,
  ];

  return categoryNamesSorted
    .map((categoryName) => (filters.get(categoryName) || []).map(({ uid }) => uid))
    .flat()
    .filter((uid) => uid);
};

const narrativeHasFilter = (narrative: Partial<Narrative>, filterUID: string) => {
    const { filters } = narrative || {};

    return Object.values(filters || {}).flat().includes(filterUID);
};

const dedupeFound = (f1: Found, f2: Found) => (
  f1.filter((f1Narrative) => !f2.some((f2Narrative) => f1Narrative.uid === f2Narrative.uid))
);

// Function returns a recursive tree of found and not-found narratives. This allows
// for nested sorting. For example, say 5 narratives have the same Drug, yet still have
// different Setting filters. The 5 narratives should then be sorted based on their
// nested Setting filters. This nested sorting should apply no matter the depth of filter
// matches two or more narratives may share.
const getSortedByFilter = (found: Found, sortedFilters: string[], index: number): Found => {
  if (found.length <= 1) {
    return found;
  }

  if (index === sortedFilters.length) {
    return found;
  }

  const filterUID = sortedFilters[index];

  const foundWithFilter = found.reduce((acc, curr) => {
    const { narrative } = curr || {};

    return narrativeHasFilter(narrative, filterUID) ? [...acc, curr] : acc;
  }, [] as Found);

  if (foundWithFilter.length === 0) {
    return getSortedByFilter(found, sortedFilters, index + 1);
  }

  const notFoundWithFilter = dedupeFound(found, foundWithFilter);

  return [
    ...getSortedByFilter(foundWithFilter, sortedFilters, index + 1),
    ...getSortedByFilter(notFoundWithFilter, sortedFilters, index + 1),
  ];
};

export const sortFoundNarratives = (found: Found, filters: FiltersMap) => {
  const sortedFound: Found = [];

  const sortedFilters = getSortedFiltersUIDs(filters);

  let dedupedFound = found;

  for (let i = 0; i < sortedFilters.length; i += 1) {
    if (!dedupedFound.length) {
      break;
    }

    const sorted = getSortedByFilter(dedupedFound, sortedFilters, i);

    if (sorted.length) {
      dedupedFound = dedupeFound(dedupedFound, sorted);
      sortedFound.push(...sorted);
    }
  }

  return sortedFound;
};
