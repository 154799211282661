import {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import clone from 'rfdc';
import { BatchMessage } from 'context/websocket';
import { useStore, useDisplay, useWebSocket } from 'context';
import {
  LevelEnum,
  findStatement,
  publishedTodayTime,
} from 'utils/helpers';

import {
  moveLevelTwoItems,
  moveLevelThreeItems,
} from './utils';

const initDragInfo: DragInfo = {
  dragType: '',
  isDragging: false,
  draggingUID: '',
  startDragTime: 0,
  dragStatementID: '',
};

const useDragDrop = (providedItems: OneSub<Statement | StatementStaged>[]) => {
  const { sendData } = useWebSocket();

  const { setIsDropMoving } = useDisplay();

  const { narratives, narrativesStaged } = useStore();

  const [dragInfo, setDragInfo] = useState(initDragInfo);

  const [renderItems, setRenderItems] = useState(providedItems);

  const noRenderItems = useRef(providedItems);

  const dragMoveItem = useCallback((destinationItem: DragItem, sourceItem: DragItem) => {
    const { level } = sourceItem;

    // Item dragged over origional position
    if (destinationItem.statementID === sourceItem.statementID) {
      setRenderItems(providedItems);
      return;
    }

    const newItems = level === LevelEnum.two
      ? moveLevelTwoItems(destinationItem, sourceItem, renderItems)
      : moveLevelThreeItems(destinationItem, sourceItem, renderItems);

    if (newItems) {
      noRenderItems.current = newItems;
      setRenderItems(newItems);
    }
  }, [renderItems]);

  const dropMoveItem = useCallback(async (sourceItem: DragItem) => {
    const { uid, level, statementID } = sourceItem;

    const pubOneSubs = narratives.get(uid)?.oneSubs || [];

    const stageOneSubs = narrativesStaged.get(uid)?.oneSubs || [];

    const {
      oneSubIndex,
      twoSubIndex,
    } = findStatement(statementID, noRenderItems.current, level);

    const {
      oneSubIndex: pubOneIndex,
      twoSubIndex: pubTwoIndex,
    } = findStatement(statementID, pubOneSubs, level);

    const {
      oneSubIndex: stageOneIndex,
      twoSubIndex: stageTwoIndex,
    } = findStatement(statementID, stageOneSubs, level);

    if (level === LevelEnum.two && oneSubIndex === stageOneIndex) {
      return;
    }

    if (
      level === LevelEnum.three
      && oneSubIndex === stageOneIndex
      && twoSubIndex === stageTwoIndex
    ) {
      return;
    }

    const narrativeStagedClone = clone()(narrativesStaged.get(uid));

    if (!narrativeStagedClone) {
      return;
    }

    if (level === LevelEnum.two && oneSubIndex !== null) {
      const statement = noRenderItems.current[oneSubIndex].statement as StatementStaged;

      statement.staging.isPositionEdited = oneSubIndex !== pubOneIndex;

      noRenderItems.current[oneSubIndex].statement = statement;
    } else if (oneSubIndex !== null && twoSubIndex !== null) {
      const statement = noRenderItems.current[oneSubIndex].twoSubs[twoSubIndex] as StatementStaged;

      statement.staging.isPositionEdited = (
        oneSubIndex !== pubOneIndex || twoSubIndex !== pubTwoIndex
      );

      noRenderItems.current[oneSubIndex].twoSubs[twoSubIndex] = statement;
    }

    narrativeStagedClone.oneSubs = noRenderItems.current as OneSub<StatementStaged>[];

    narrativeStagedClone.publishTime = publishedTodayTime();

    setIsDropMoving(true);
    try {
      await new BatchMessage(
        sendData,
        {
          key: 'narrativesStaged',
          field: uid,
          value: narrativeStagedClone,
        },
      ).send();
    } catch {
      setRenderItems(providedItems);
    }
    setIsDropMoving(false);
  }, [narratives, narrativesStaged]);

  useEffect(() => {
    setRenderItems(providedItems);
    noRenderItems.current = providedItems;
  }, [providedItems]);

  return {
    dragInfo,
    renderItems,
    setDragInfo,
    dragMoveItem,
    dropMoveItem,
  };
};

export default useDragDrop;
