import {
    useRef,
    useMemo,
    useState,
    useEffect,
    useCallback,
} from 'react';
import { useView } from 'context';
import { useWindowClick } from 'utils/hooks';

import notification_arrow from 'assets/images/shared/notification-arrow.png';

const useNotification = () => {
    const notificationRef = useRef<HTMLDivElement | null>(null);

    const { isDesktopView } = useView();

    const [width, setWidth] = useState('w-40');

    const [message, setMessage] = useState('');

    const [top, setTop] = useState(20);

    const [right, setRight] = useState(-25);

    const [isOpen, setIsOpen] = useWindowClick(notificationRef);

    const [styles, setStyles] = useState<NotificationStyles>({});

    const [parentWidth, setParentWidth] = useState(16);

    const openNotification = useCallback((
        newMessage?: string,
        style?: NotificationStyles,
    ) => {
        setMessage(newMessage || '');
        if (style?.width) {
            setWidth(style.width);
        } else {
            setWidth(isDesktopView ? 'w-44' : 'w-48');
        }

        setTimeout(() => {
            setStyles(style || {});
            setIsOpen(true);
        });
    }, [isDesktopView]);

    const notification = useMemo(() => {
        if (!message) {
            return <></>;
        }

        return (
            <div
                ref={notificationRef}
                className={`
                    z-50 absolute open-animation flex flex-col
                    text-sm md:text-xs text-center text-azBlue-700
                    ${width}
                `}
                style={{
                    top,
                    right,
                    display: isOpen ? 'block' : 'none',
                }}
            >
                <div className="flex justify-end">
                    <img
                        style={{
                            marginRight: Math.abs(right ? (right - parentWidth / 2) + 10 : 0),
                        }}
                        width={22}
                        height={17}
                        src={notification_arrow}
                        alt="Notification arrow"
                    />
                </div>
                <p
                    className="p-3 select-border"
                    style={{ backgroundColor: '#CCD7E0' }}
                >
                    {message}
                </p>
            </div>
        );
    }, [isOpen, message, width, right, top, parentWidth]);

    useEffect(() => {
        if (notificationRef.current && isOpen) {
            const notificationParent = notificationRef.current.parentElement;

            if (notificationParent) {
                const windowWidth = window.innerWidth;

                const {
                    x,
                    width: w,
                    height: h,
                } = notificationParent.getBoundingClientRect();

                setTop(h + 2);

                if (styles.parentWidth) {
                    setParentWidth(styles.parentWidth);
                } else {
                    setParentWidth(notificationParent.clientWidth);
                }

                if (styles.right) {
                    setRight(styles.right);
                    return;
                }

                if (windowWidth - x > 150) {
                    setRight(-(notificationRef.current.clientWidth / 2) + (w / 2));
                } else if (windowWidth - x > 80) {
                    setRight(-25);
                } else if (windowWidth < 768) {
                    setRight(0);
                } else {
                    setRight(-15);
                }
            }
        }
    }, [isOpen, styles]);

    useEffect(() => {
        let timeout: null | NodeJS.Timeout = null;

        if (isOpen) {
            timeout = setTimeout(() => {
                setIsOpen(false);
            }, 10000);
        }

        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
        };
    }, [isOpen]);

    return {
        notification,
        openNotification,
    };
};

export default useNotification;
