import {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useStore } from 'context';
import { InfoIcon } from 'components/shared';
import {
  filterNames,
  FilterCategoryEnum,
  findFiltersByReference,
} from 'utils/helpers';

import { MultiSelect } from './ReactSelect';

const noneOption = {
  uid: 'none',
  label: 'None',
  value: 'none',
};

interface CreateMultiSelectProps {
  title: string;
  infoMsg: string;
  innerRef: React.MutableRefObject<MultiRef>;
  onChange: React.Dispatch<React.SetStateAction<MultiOptions>>;
  isLoading: boolean;
  includeNone: boolean | undefined;
  multiOptions: MultiOptions | undefined;
  defaultValue: MultiOptions;
}

const CreateMultiSelect = ({
  title,
  infoMsg,
  innerRef,
  onChange,
  isLoading,
  includeNone,
  multiOptions,
  defaultValue,
}: CreateMultiSelectProps) => {
  const [showClear, setShowClear] = useState(false);

  const filteredOptions = (multiOptions || []).filter((mo) => mo.label && mo.value);

  const options = includeNone
    ? [...filteredOptions, noneOption]
    : filteredOptions;

  const inputId = `${title}-${new Date().getTime()}-search`;

  return (
    <div className="grid">
      <div className="text-azBlue-700 flex justify-between">
        <span className="md:text-sm font-semibold inline-flex gap-1">
          <label
            className="md:text-sm font-semibold"
            htmlFor={inputId}
          >
            {title}
          </label>
          <InfoIcon message={infoMsg} className="self-start" />
        </span>
        <button
          type="button"
          className="text-sm md:text-xs self-end"
          style={{
            display: showClear ? 'block' : 'none',
          }}
          onClick={() => {
            if (innerRef.current) {
              innerRef.current.clearValue();
            }
          }}
        >
          clear all
        </button>
      </div>
      <MultiSelect
        innerProps={{
          inputId,
          isLoading,
          defaultValue,
          onChange: (updatedOptions) => {
            onChange(updatedOptions);
            setShowClear(updatedOptions?.length > 0);
            return updatedOptions;
          },
          options,
          isDisabled: isLoading,
        }}
        innerRef={innerRef}
      />
    </div>
  );
};

interface FilterSelectsProps {
  isLoading?: boolean;
  resetSearch?: ResetSearchInterface;
  defaultNone?: boolean;
  includeNone?: boolean;
  filterReferences?: FilterReferences;
  setSearchFilters: React.Dispatch<React.SetStateAction<Filters>>
}

const FilterSelects = ({
  resetSearch,
  filterReferences,
  setSearchFilters,
  isLoading = false,
  includeNone = false,
  defaultNone = false,
}: FilterSelectsProps) => {
  const { filters } = useStore();

  const defaultOption = defaultNone ? [noneOption] : [];

  const [drugOptions, setDrugOptions] = useState<MultiOptions>([]);
  const [trialOptions, setTrialOptions] = useState<MultiOptions>([]);
  const [tumourOptions, setTumourOptions] = useState<MultiOptions>([]);
  const [settingOptions, setSettingOptions] = useState<MultiOptions>([]);
  const [elementOptions, setElementOptions] = useState<MultiOptions>([]);
  const [sectionOptions, setSectionOptions] = useState<MultiOptions>([]);
  const [overviewOptions, setOverviewOptions] = useState<MultiOptions>([]);
  const [biomakerOptions, setBiomakerOptions] = useState<MultiOptions>([]);

  const drugsRef = useRef<MultiRef>(null);
  const trialsRef = useRef<MultiRef>(null);
  const tumoursRef = useRef<MultiRef>(null);
  const sectionRef = useRef<MultiRef>(null);
  const settingsRef = useRef<MultiRef>(null);
  const elementsRef = useRef<MultiRef>(null);
  const overviewRef = useRef<MultiRef>(null);
  const biomakerRef = useRef<MultiRef>(null);

  if (resetSearch) {
    resetSearch.setResetSelects(useCallback(() => {
      drugsRef.current?.clearValue();
      trialsRef.current?.clearValue();
      tumoursRef.current?.clearValue();
      sectionRef.current?.clearValue();
      settingsRef.current?.clearValue();
      elementsRef.current?.clearValue();
      overviewRef.current?.clearValue();
      biomakerRef.current?.clearValue();
    }, []));
  }

  const updateOptions = useCallback((
    ref: React.MutableRefObject<MultiRef>,
    searchFilters: MultiOptions,
    updatedFilters: MultiOptions | undefined,
  ) => {
    if (ref.current) {
      if (updatedFilters?.length) {
        const selectedUpdated = updatedFilters.filter((uf) => (
          searchFilters.some((sf) => uf.uid === sf.uid)
        ));
        ref.current.setValue(
          selectedUpdated.length ? selectedUpdated : defaultOption,
          'select-option',
        );
      } else {
        ref.current.setValue(defaultOption, 'select-option');
      }
    }
  }, []);

  const setDefault = useCallback((
    ref: React.MutableRefObject<MultiRef>,
    searchFilters: MultiOptions,
  ) => {
    if (ref.current) {
      ref.current.setValue(searchFilters, 'select-option');
    }
  }, []);

  useEffect(() => {
    updateOptions(drugsRef, drugOptions, filters.get(FilterCategoryEnum.drugs));
    updateOptions(trialsRef, trialOptions, filters.get(FilterCategoryEnum.trials));
    updateOptions(tumoursRef, tumourOptions, filters.get(FilterCategoryEnum.tumours));
    updateOptions(sectionRef, sectionOptions, filters.get(FilterCategoryEnum.section));
    updateOptions(settingsRef, settingOptions, filters.get(FilterCategoryEnum.settings));
    updateOptions(elementsRef, elementOptions, filters.get(FilterCategoryEnum.elements));
    updateOptions(overviewRef, overviewOptions, filters.get(FilterCategoryEnum.overviews));
    updateOptions(biomakerRef, biomakerOptions, filters.get(FilterCategoryEnum.biomarkers));
  }, [filters]);

  useEffect(() => {
    const foundDefaults = findFiltersByReference(filterReferences, filters, defaultOption);

    setDefault(drugsRef, foundDefaults.drugs);
    setDefault(trialsRef, foundDefaults.trials);
    setDefault(tumoursRef, foundDefaults.tumours);
    setDefault(sectionRef, foundDefaults.section);
    setDefault(settingsRef, foundDefaults.settings);
    setDefault(elementsRef, foundDefaults.elements);
    setDefault(overviewRef, foundDefaults.overviews);
    setDefault(biomakerRef, foundDefaults.biomarkers);
  }, [filters, filterReferences]);

  useEffect(() => {
    setSearchFilters({
      [FilterCategoryEnum.drugs]: drugOptions,
      [FilterCategoryEnum.trials]: trialOptions,
      [FilterCategoryEnum.section]: sectionOptions,
      [FilterCategoryEnum.tumours]: tumourOptions,
      [FilterCategoryEnum.settings]: settingOptions,
      [FilterCategoryEnum.elements]: elementOptions,
      [FilterCategoryEnum.overviews]: overviewOptions,
      [FilterCategoryEnum.biomarkers]: biomakerOptions,
    });
  }, [
    drugOptions,
    trialOptions,
    tumourOptions,
    sectionOptions,
    settingOptions,
    elementOptions,
    overviewOptions,
    biomakerOptions,
  ]);

  return (
    <>
      <CreateMultiSelect
        title={filterNames[FilterCategoryEnum.tumours]}
        infoMsg="Filter by specific tumour type of interest"
        innerRef={tumoursRef}
        onChange={setTumourOptions}
        multiOptions={filters.get(FilterCategoryEnum.tumours)}
        defaultValue={tumourOptions}
        includeNone={includeNone}
        isLoading={isLoading}
      />
      <CreateMultiSelect
        title={filterNames[FilterCategoryEnum.section]}
        infoMsg="Refine your search to the T3 narrative section of interest"
        innerRef={sectionRef}
        onChange={setSectionOptions}
        multiOptions={filters.get(FilterCategoryEnum.section)}
        defaultValue={sectionOptions}
        includeNone={includeNone}
        isLoading={isLoading}
      />
      <CreateMultiSelect
        title={filterNames[FilterCategoryEnum.drugs]}
        infoMsg="Filter by specific drug. You may select one or more options"
        innerRef={drugsRef}
        onChange={setDrugOptions}
        multiOptions={filters.get(FilterCategoryEnum.drugs)}
        defaultValue={drugOptions}
        includeNone={includeNone}
        isLoading={isLoading}
      />
      <CreateMultiSelect
        title={filterNames[FilterCategoryEnum.settings]}
        infoMsg="Filter by disease setting and treatment approach"
        innerRef={settingsRef}
        onChange={setSettingOptions}
        multiOptions={filters.get(FilterCategoryEnum.settings)}
        defaultValue={settingOptions}
        includeNone={includeNone}
        isLoading={isLoading}
      />
      <CreateMultiSelect
        title={filterNames[FilterCategoryEnum.elements]}
        infoMsg="Filter by topic/criteria of relevance"
        innerRef={elementsRef}
        onChange={setElementOptions}
        multiOptions={filters.get(FilterCategoryEnum.elements)}
        defaultValue={elementOptions}
        includeNone={includeNone}
        isLoading={isLoading}
      />
      <CreateMultiSelect
        title={filterNames[FilterCategoryEnum.biomarkers]}
        infoMsg="Filter by biomarker or phenotype"
        innerRef={biomakerRef}
        onChange={setBiomakerOptions}
        multiOptions={filters.get(FilterCategoryEnum.biomarkers)}
        defaultValue={biomakerOptions}
        includeNone={includeNone}
        isLoading={isLoading}
      />
      <CreateMultiSelect
        title={filterNames[FilterCategoryEnum.trials]}
        infoMsg="Filter by trial. Only key trials are searchable"
        innerRef={trialsRef}
        onChange={setTrialOptions}
        multiOptions={filters.get(FilterCategoryEnum.trials)}
        defaultValue={trialOptions}
        includeNone={includeNone}
        isLoading={isLoading}
      />
      <CreateMultiSelect
        title={filterNames[FilterCategoryEnum.overviews]}
        infoMsg="Refine your search to focus on specific statements (e.g. T3 narratives)"
        innerRef={overviewRef}
        onChange={setOverviewOptions}
        multiOptions={filters.get(FilterCategoryEnum.overviews)}
        defaultValue={overviewOptions}
        includeNone={includeNone}
        isLoading={isLoading}
      />
    </>
  );
};

export default FilterSelects;
