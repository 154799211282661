import { LevelEnum } from './enums';
import { findStatement } from './narrative';

export const isVisualFileType = (imgFile: File) => {
    if (!imgFile) return false;
    const { type } = imgFile;
    return type === 'image/jpeg' || type === 'image/png' || type === 'image/gif';
};

export const isVisualSizeOK = (imgFile: File) => {
    if (!imgFile) return false;
    const maxSize = 5000000;
    if (imgFile.size <= maxSize) return true;
    return false;
};

const readFile = (imgFile: File) => new Promise<string | ArrayBuffer>((result, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => result(e.target?.result || '');
    reader.onerror = (e) => reject(e);
    reader.readAsDataURL(imgFile);
});

const createImage = async (imgFile: File) => {
    const image = new Image();
    const imgURL = await readFile(imgFile);
    if (imgURL instanceof ArrayBuffer) {
        return null;
    }
    return new Promise<HTMLImageElement>((resolve, reject) => {
        image.onload = () => resolve(image);
        image.onerror = (e) => reject(e);
        image.src = imgURL;
    });
};

export const shrinkVisual = async (imgFile: File) => {
    const image = await createImage(imgFile);
    if (!image) return null;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return null;
    const maxWidth = image.width > 100 ? 100 : image.width;
    const scaleRatio = maxWidth / image.width;
    canvas.width = maxWidth;
    canvas.height = image.height * scaleRatio;
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    return new Promise<Blob | null>((resolve) => {
        canvas.toBlob((file) => resolve(file));
    });
};

const getImgFilename = (url: string) => url.split('/').slice(-1)[0];

const findDeleteVisualUIDs = (
  pub: Statement | undefined | null,
  staged: StatementStaged | undefined | null,
  type: 'publish' | 'discard',
) => {
    const delVisuals: string[] = [];

    const pubImage = getImgFilename(pub?.visual || '');
    const stagedImage = getImgFilename(staged?.visual || '');

    const isPublish = type === 'publish';
    const isDiscard = type === 'discard';
    const isDeleted = staged?.staging?.isDeleted || false;
    const isImageMatch = pubImage && pubImage === stagedImage;

    if (isPublish && isDeleted && isImageMatch) {
        delVisuals.push(pubImage);
    } else if (isPublish && isDeleted && !isImageMatch) {
        delVisuals.push(...([pubImage, stagedImage].filter((filename) => filename)));
    } else if (isPublish && pubImage && !isImageMatch) {
        delVisuals.push(pubImage);
    } else if (isDiscard && stagedImage && !isImageMatch) {
        delVisuals.push(stagedImage);
    }

    return delVisuals;
};

export const getDeleteVisualUIDs = (
  narrativePub: Partial<Narrative<Statement>> | undefined,
  narrativeStaged: Partial<Narrative<StatementStaged>> | undefined,
  type: 'publish' | 'discard',
) => {
    const delVisuals: string[] = [];

    const {
        oneSubs: pubOneSubs,
        statement: pubStatement,
    } = narrativePub || {};

    const {
        oneSubs: stagedOneSubs,
        statement: stagedStatement,
    } = narrativeStaged || {};

    // Checking top level statement.
    delVisuals.push(
        ...findDeleteVisualUIDs(pubStatement, stagedStatement, type),
    );

    // Checking nested oneSubs and twoSubs staements.
    stagedOneSubs?.forEach((stagedOneSub) => {
        // Statement position may have changed, so using index is not work.
        // Using findStatement helper function to find statement via matching UID.
        const { statement: pubOneStatement } = findStatement(
            stagedOneSub.statement?.uid,
            pubOneSubs,
            LevelEnum.two,
        );

        delVisuals.push(...findDeleteVisualUIDs(pubOneStatement, stagedOneSub.statement, type));

        stagedOneSub.twoSubs?.forEach((stagedTwoStatement) => {
            const { statement: pubTwoStatement } = findStatement(
                stagedTwoStatement.uid,
                pubOneSubs,
                LevelEnum.three,
            );

            delVisuals.push(...findDeleteVisualUIDs(pubTwoStatement, stagedTwoStatement, type));
        });
    });

    return [...new Set(delVisuals)];
};
