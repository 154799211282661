import { useMemo, useState, useEffect } from 'react';
import { useView } from 'context';
import FocusTrap from 'focus-trap-react';
import { FormMessages } from 'components/shared';

const useOnline = () => {
    const { isStaging } = useView();

    const [isOnline, setIsOnline] = useState(true);

    const OfflineModal = useMemo(() => {
        const error = 'Connection lost - an active internet connection is required to edit filters or narratives.';
        if (!isOnline && isStaging) {
          return (
            <FocusTrap>
              <div
                className="flex justify-center items-center fixed top-0 bottom-0 left-0 right-0 bg-stone-900 bg-opacity-70 cursor-wait"
                style={{ zIndex: 101 }}
              >
                <div
                  role="grid"
                  tabIndex={0}
                  className="w-full py-16 bg-white md:w-128 cursor-wait outline-none"
                >
                  <FormMessages error={error} success="" warning="" />
                </div>
              </div>
            </FocusTrap>
          );
        }
        return <></>;
      }, [isOnline, isStaging]);

    useEffect(() => {
        const handleOnline = () => setIsOnline(navigator.onLine);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOnline);
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOnline);
        };
    }, []);

    return { isOnline, OfflineModal };
};

export default useOnline;
